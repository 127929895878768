import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import GET_SHIP_TOS from '../queries/getShipTos';

interface ShipTo {
  __typename: 'ShipTo';
  id: number;
  name: string;
  city: string | null;
  leadtimeInDays: number;
}

interface getShipTos {
  getShipTos: ShipTo[];
}

const useShipTos = (customerId: number) => {
  const [list, setList] = useState<ShipTo[]>([]);

  const { data } = useQuery<getShipTos>(GET_SHIP_TOS, {
    variables: {
      customerId: customerId,
    },
  });

  useEffect(() => {
    if (data?.getShipTos) {
      setList(data.getShipTos);
    }
  }, [data]);

  return list;
};

export default useShipTos;
