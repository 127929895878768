import { css } from '@emotion/react';
import type { CSSInterpolation } from '@emotion/serialize';
import { tokens } from '@lego/core-colors';

import { baseSpacing, colors, designToken } from '../theme';

export const optionsContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  margin: 0,
  borderRadius: 6,
  backgroundColor: colors.white,
  boxShadow:
    '0px 8px 15px 5px rgb(0 0 0 / 10%), 8px 15px 15px 5px rgb(0 0 0 / 5%), -8px 15px 15px 5px rgb(0 0 0 / 5%)',
  zIndex: 20,
  position: 'absolute',
  maxHeight: 400,
  overflow: 'auto',
});

export const optionStyle = css({
  margin: 0,
  padding: baseSpacing * 1.5,
  transitionProperty: 'all',
  transitionDuration: '150ms',
  lineHeight: 1.3,
  scrollBehavior: 'smooth',
  fontSize: 'inherit',
  '&:hover, :focus': {
    backgroundColor: designToken.background.blue,
    outline: 'none',
  },
});

export const selectContainerStyle = (isDisabled: boolean, hideBorder = false) => {
  const style: CSSInterpolation = {
    position: 'relative',
    outline: 'none',
    cursor: 'pointer',
    '> svg': {
      fill: isDisabled ? tokens.color.core.gray[600] : tokens.color.core.gray[1100],
      width: 20,
      height: 20,
      position: 'absolute',
      top: baseSpacing * 1.5,
      right: baseSpacing * 1.5,
      pointerEvents: 'none',
    },
  };
  const borderAndColor: CSSInterpolation = {};
  if (!isDisabled && !hideBorder) {
    borderAndColor.borderColor = designToken.interactive.hover;
  }
  if (hideBorder) {
    borderAndColor.color = designToken.interactive.active;
  }
  style['&:focus > div'] = borderAndColor;
  return css(style);
};

export const selectBoxStyle = (isDisabled = false, isInvalid = false, hideBorder = false) => {
  let borderColor: string = isDisabled ? tokens.color.core.gray[100] : tokens.color.core.gray[200];
  if (isInvalid) {
    borderColor = designToken.error.default;
  }
  if (hideBorder) {
    borderColor = 'transparent';
  }
  const style: CSSInterpolation = {
    boxShadow: 'none',
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: borderColor,
    backgroundColor: isDisabled ? tokens.color.core.gray[100] : colors.white,
    fontSize: 14,
    height: 40,
    lineHeight: '38px',
    whiteSpace: 'nowrap',
    paddingLeft: baseSpacing,
    boxSizing: 'border-box',
  };
  if (hideBorder) {
    style.textAlign = 'right';
    style.paddingRight = baseSpacing * 5;
    style.color = isInvalid ? designToken.error.default : designToken.interactive.default;
    style.backgroundColor = 'transparent';
  }
  if (isDisabled) {
    style.color = tokens.color.core.gray[500];
    style.pointerEvents = 'none';
    style.userSelect = 'none';
  }
  return css(style);
};

export const selectOptionStyle = (indentSteps = 0) => {
  return css(optionStyle, {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    '> svg': {
      fill: designToken.interactive.hover,
      width: 16,
    },
    paddingLeft: `${12 + indentSteps * 12}px`,
  });
};

export const selectBoxOptionsContainerStyle = (show: boolean) => {
  return css(optionsContainerStyle, {
    /**
     * When opening the selector with keyboard navigation (e.g. arrow down), we want to move
     * focus to the first child element (just like a "normal" <select> element).
     * Javascript won't let us do that if the child elements are hidden.
     * Hence we hide them with 'opacity' rather than 'display' or 'visibility'.
     */
    opacity: show ? 1 : 0,
    pointerEvents: show ? 'all' : 'none',
  });
};

export const hideOverflowStyle = css({
  pointerEvents: 'none',
  width: '100%',
  display: 'inline-block',
  lineHeight: 'inherit',
  overflow: 'hidden',
  '&:after': {
    display: 'block',
    position: 'absolute',
    top: 2,
    right: 1,
    content: '""',
    width: 72,
    height: 'calc(100% - 4px)',
    background: `linear-gradient(90deg, ${designToken.transparent.default} 0%, ${colors.white} 50%)`,
  },
});

export const disabledHideOverflowStyle = css({
  '&:after': {
    background: `linear-gradient(90deg, ${designToken.transparent.lightOverlay} 0%, ${tokens.color.core.gray[100]} 50%)`,
  },
});
