import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

const GET_TERMS_OF_SALES_ACCEPTANCE_STATE = gql`
  query GetTermsOfSalesAcceptanceState($customerId: Int!) {
    getTermsOfSalesAcceptanceState(customerId: $customerId)
  }
`;

export const useTermsofSalesAcceptanceCheck = (id: number, redirectUrl: string) => {
  const [
    getTermsOfSalesAcceptanceState,
    { data: acceptanceStateResponse, error: acceptanceStateError },
  ] = useLazyQuery(GET_TERMS_OF_SALES_ACCEPTANCE_STATE);

  useEffect(() => {
    if (id) {
      getTermsOfSalesAcceptanceState({
        variables: {
          customerId: id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (acceptanceStateResponse && !acceptanceStateResponse.getTermsOfSalesAcceptanceState) {
      location.assign(redirectUrl);
    }
  }, [acceptanceStateResponse, redirectUrl, id]);

  return acceptanceStateError || null;
};
