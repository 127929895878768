import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { tableHeaderRightStyle, tableHeaderStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import {
  idWidthStyle,
  nameWidthStyle,
  priceWidthStyle,
  quantityWidthStyle,
  themeWidthStyle,
} from './tableStyles';

const localTableHeadStyle = css({
  display: 'table-row-group',
  position: 'sticky',
  width: '100%',
  top: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    height: 24,
    width: '100%',
    bottom: -23,
    left: 0,
    background: `linear-gradient(to bottom, ${colors.white} 0%, ${colors.white} 5%, ${designToken.transparent.default})`,
    pointerEvents: 'none',
  },
});

export const SuccessfulBulkCartUpdateSummaryHeader = () => {
  const { item_id, name, theme, quantity, your_price } = useLabels();
  // it's prettending to be a header, but needs to be a sticky row, so rest of table is scrollable
  return (
    <tr css={localTableHeadStyle}>
      <th css={[tableHeaderStyle, idWidthStyle]}>{item_id}</th>
      <th css={[tableHeaderStyle, nameWidthStyle]}>{name}</th>
      <th css={[tableHeaderStyle, themeWidthStyle]}>{theme}</th>
      <th css={[tableHeaderRightStyle, quantityWidthStyle]}>{quantity}</th>
      <th css={[tableHeaderRightStyle, priceWidthStyle]}>{your_price}</th>
    </tr>
  );
};
