import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { ShipTo } from '@lego/b2b-unicorn-data-access-layer';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { DateInput, designToken, SelectBox } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors } from '@lego/b2b-unicorn-ui-constants';
import { addBusinessDays, formatISO9075, isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import React, { useEffect, useMemo } from 'react';

import { hintStyle } from '../../../common/styles';
import { IDeliveryInfo } from '../../../common/types';

const shipToInputContainerStyle = (isDisabled: boolean) =>
  css({
    paddingBottom: baseSpacing * 2,
    label: {
      display: 'inline-block',
      paddingBottom: baseSpacing,
      color: isDisabled ? designToken.text.default : 'inherit',
    },
  });

const dateInputContainerStyle = (isDisabled: boolean) =>
  css({
    position: 'relative',
    paddingBottom: baseSpacing * 3,
    width: '100%',
    label: {
      display: 'inline-block',
      paddingBottom: baseSpacing,
      color: isDisabled ? colors.klik.slate400 : 'inherit',
    },
  });

// Hardcoded date intervals to exclude, should be moved to backend ASAP! 🙈
const excludedDateIntervals: React.ComponentProps<typeof DateInput>['excludeDateIntervals'] = [
  { start: new Date(2023, 11, 21), end: new Date(2024, 0, 2) },
];

type DeliveryInformationProps = {
  disabledFromOutside: boolean;
  shipTos: ShipTo[];
  shippingAddressId?: number;
  shipToOnChangeHandler: (shippingId: number) => void;
  minDate?: Date;
  maxDate?: Date;
  requestedDeliveryDate: Date | null;
  dateOnChangeHandler: (date: Date | null) => void;
  onValidInfoHandler: (info?: IDeliveryInfo) => void;
};

const DeliveryInformation: React.FC<DeliveryInformationProps> = ({
  disabledFromOutside,
  shipTos,
  shippingAddressId,
  shipToOnChangeHandler,
  minDate,
  maxDate,
  requestedDeliveryDate: requestedDeliveryDateExternal,
  dateOnChangeHandler,
  onValidInfoHandler,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    shipping_address,
    shipping_address_hint,
    shipping_address_select_placeholder,
    delivery_date_requested,
    requested_delivery_date_select_placeholder,
  } = useLabels();

  const requestedDeliveryDate = useMemo(() => {
    if (requestedDeliveryDateExternal === null) {
      return null;
    }

    if (
      (isAfter(requestedDeliveryDateExternal, excludedDateIntervals[0].start) &&
        isBefore(requestedDeliveryDateExternal, excludedDateIntervals[0].end)) ||
      isSameDay(requestedDeliveryDateExternal, excludedDateIntervals[0].start) ||
      isSameDay(requestedDeliveryDateExternal, excludedDateIntervals[0].end)
    ) {
      const requestedDeliveryDate = addBusinessDays(excludedDateIntervals[0].end, 1);
      dateOnChangeHandler(requestedDeliveryDate);
      return requestedDeliveryDate;
    }

    return requestedDeliveryDateExternal;
  }, [dateOnChangeHandler, requestedDeliveryDateExternal]);

  //client-side validation
  const hasValidShipTos = shipTos && !!shipTos.length;
  const isShippingAddressValid = !!shippingAddressId && typeof shippingAddressId !== 'string';
  const isDeliveryDateValid = requestedDeliveryDate && isValid(requestedDeliveryDate);
  const isFormValid = hasValidShipTos && isShippingAddressValid && isDeliveryDateValid;

  const deliveryInfo = useMemo(
    () =>
      isFormValid
        ? {
            customerId: selectedCustomer.id,
            shipToId: shippingAddressId,
            requestedDeliveryDate: formatISO9075(requestedDeliveryDate, {
              representation: 'date',
            }),
          }
        : undefined,
    [isFormValid, requestedDeliveryDate, selectedCustomer.id, shippingAddressId]
  );

  useEffect(() => {
    onValidInfoHandler(deliveryInfo);
  }, [deliveryInfo, onValidInfoHandler]);

  const shipToIsDisabled = !hasValidShipTos || disabledFromOutside;
  const shipToHasWarning = !shippingAddressId && shipTos?.length > 1;

  const datePickerIsDisabled =
    !hasValidShipTos || !isShippingAddressValid || disabledFromOutside || !minDate;

  return (
    <section>
      <div css={shipToInputContainerStyle(shipToIsDisabled)}>
        <label>{shipping_address}</label>
        <SelectBox
          label={shipping_address_select_placeholder}
          values={shipTos.map((shipTo) => {
            return {
              displayText: `${shipTo.id}-${shipTo.name}-${shipTo.city}`,
              value: shipTo.id,
            };
          })}
          selectedValues={shippingAddressId}
          optionsChangeHandler={(value) => shipToOnChangeHandler(value as number)}
          disabled={shipToIsDisabled}
          invalid={shipToHasWarning}
        />
        {shipToHasWarning && <p css={hintStyle}>{shipping_address_hint}</p>}
      </div>
      <div css={dateInputContainerStyle(datePickerIsDisabled)}>
        <label>{delivery_date_requested}</label>
        <DateInput
          minDate={minDate}
          maxDate={maxDate}
          date={requestedDeliveryDate}
          onDateChange={dateOnChangeHandler}
          disabled={datePickerIsDisabled}
          locale={selectedCustomer.locale}
          placeholder={requested_delivery_date_select_placeholder}
          excludeDateIntervals={excludedDateIntervals}
        />
      </div>
    </section>
  );
};

export default DeliveryInformation;
