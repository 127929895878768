import { hash } from 'ohash';
import React, { useEffect, useState } from 'react';

import { Card } from '../../ui/Card';
import { FlexBox } from '../../ui/FlexBox';
import { Icon, IconType } from '../../ui/Icon';
import { List } from '../../ui/List';
import { Paragraph } from '../../ui/Paragraph';
import { baseSpacing, designToken } from '../../ui/theme';
import { FileUploadListItem } from './FileUploadListItem';
import { FileUploadService } from './service/FileUpload.service';
import { FileUploadItem } from './service/FileUploadItem';

type FileUploadProps = {
  onChange: (files: FileUploadItem[]) => void;

  /**
   * getFileUploadUrl callback that is called to get the upload URL for the file
   */
  getFileUploadUrl: ConstructorParameters<typeof FileUploadItem>[2];

  /**
   * mime types that are allowed to be uploaded, e.g. ['image/jpeg', 'image/png']
   */
  acceptedFileTypes?: string[];

  /**
   * Whether multiple files can be uploaded
   */
  multiple?: boolean;

  translations: {
    file_upload_drag_and_drop: string;
    file_upload_browse_files: string;
  };

  fileService: FileUploadService;
  fileServiceStack?: string;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  onChange,
  acceptedFileTypes,
  multiple,
  getFileUploadUrl,
  translations,
  fileService,
  fileServiceStack,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [inDropZone, setInDropZone] = React.useState(false);
  const [innerFiles, setInnerFiles] = useState<FileUploadItem[]>([]);

  useEffect(() => {
    const filesObservable = fileServiceStack
      ? fileService.filesByStack(fileServiceStack)
      : fileService.files;
    const subscription = filesObservable.subscribe((files) => {
      setInnerFiles(files);
      onChange(files);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [fileService, fileServiceStack, onChange]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
  ) => {
    const targetFiles =
      (event as React.ChangeEvent<HTMLInputElement>).target.files ||
      (event as React.DragEvent<HTMLDivElement>).dataTransfer.files;

    if (!targetFiles) {
      return;
    }

    const filesWithHash = Array.from(targetFiles).map((file) => ({
      file,
      hash: hash(`${file.name}-${file.size}-${file.type}`),
    }));

    for (const file of filesWithHash) {
      fileService.addFile(file.hash, file.file, getFileUploadUrl, fileServiceStack);
    }

    if (fileInputRef.current && fileInputRef.current.files) {
      fileInputRef.current.value = '';
      fileInputRef.current.files = null;
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInDropZone(false);
    handleChange(event);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInDropZone(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInDropZone(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInDropZone(false);
  };

  const handleOnRemove = (file: FileUploadItem) => {
    fileService.removeFile(file);
  };

  return (
    <FlexBox
      fullWidth
      flexDirection={'column'}
    >
      <div
        onClick={() => fileInputRef.current?.click()}
        css={{ cursor: 'pointer' }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <Card
          borderType={'dashed'}
          borderColor={inDropZone ? 'border.information' : undefined}
          boxShadow={inDropZone ? 'lightBlue400' : undefined}
          padding={{ left: baseSpacing * 2, right: baseSpacing * 2 }}
          centered
        >
          <FlexBox
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Icon
              type={IconType.UPLOAD}
              height={baseSpacing * 3}
              color={designToken.text.muted}
            />
            <Paragraph
              fontSize={'medium'}
              designToken={'text.secondary'}
            >
              {translations.file_upload_drag_and_drop}
            </Paragraph>
            <Paragraph
              fontSize={'small'}
              designToken={'information.default'}
            >
              {translations.file_upload_browse_files}
            </Paragraph>
            <input
              ref={fileInputRef}
              type="file"
              onChange={handleChange}
              accept={acceptedFileTypes?.join(',')}
              multiple={multiple}
              css={{ display: 'none' }}
            />
          </FlexBox>
        </Card>
      </div>
      <List>
        {innerFiles.map((file) => (
          <FileUploadListItem
            fileItem={file}
            onRemove={handleOnRemove}
            key={file.uniqueHashValue}
          />
        ))}
      </List>
    </FlexBox>
  );
};
