import { useFormik } from 'formik';
import React, { useContext } from 'react';

import { ClaimItem, InvoiceOrderItem } from './ClaimProvider';
import { ClaimItemFromValidationSchema, claimItemSchema, claimTypes } from './validation';

type ClaimTypes = (typeof claimTypes)[number];
type ClaimTypeTranslations = {
  [K in ClaimTypes]: string;
};
type ClaimItemFormContextTranslations = {
  header: string;
  labels: {
    claimType: string;
    claimedPieces: string;
    expectedPrice: string;
    comment: string;
    uploadDocumentation: string;
  };
  helperTexts: {
    claimType: string;
    claimedPieces: string;
    expectedPrice: string;
    uploadDocumentationDragAndDrop: string;
    uploadDocumentationBrowse: string;
    requiredFields: string;
  };
  claimTypeOptions: ClaimTypeTranslations;
  buttons: {
    close: string;
    addClaim: string;
    updateClaim: string;
  };
};

type ClaimItemFormContextProps = {
  translations: ClaimItemFormContextTranslations;
  locale: string;
  invoiceItem: InvoiceOrderItem;
  claimItem?: ClaimItem;
  formik: ReturnType<typeof useFormik>;
  resetForm: () => void;
};

const ClaimItemFormContext = React.createContext<ClaimItemFormContextProps>({
  translations: {
    header: '',
    labels: {
      claimType: '',
      claimedPieces: '',
      expectedPrice: '',
      comment: '',
      uploadDocumentation: '',
    },
    helperTexts: {
      claimType: '',
      claimedPieces: '',
      expectedPrice: '',
      uploadDocumentationDragAndDrop: '',
      uploadDocumentationBrowse: '',
      requiredFields: '',
    },
    claimTypeOptions: {} as ClaimTypeTranslations,
    buttons: {
      close: '',
      addClaim: '',
      updateClaim: '',
    },
  },
  locale: '',
  invoiceItem: {} as InvoiceOrderItem,
  formik: {} as ReturnType<typeof useFormik>,
  resetForm: () => {},
});

type ClaimItemFormContextProviderProps = {
  children: React.ReactNode;
  translations: ClaimItemFormContextTranslations;
  locale: string;
  invoiceItem: InvoiceOrderItem;
  claimItem?: ClaimItem;
  onSubmit: (values: ClaimItemFromValidationSchema) => void;
};
export const ClaimItemFormContextProvider: React.FC<ClaimItemFormContextProviderProps> = ({
  children,
  translations,
  locale,
  invoiceItem,
  claimItem,
  onSubmit,
}) => {
  const formInitialValues: Partial<ClaimItemFromValidationSchema> = {
    materialId: invoiceItem.materialId,
    orderNumber: invoiceItem.orderNumber,
    invoiceLineNumber: invoiceItem.invoiceLineNumber,
    pieces: claimItem?.pieces || 0,
    expectedPrice:
      (claimItem?.expectedPrice?.__typename === 'Price' &&
        claimItem?.expectedPrice.estimatedNetInvoicedPrice) ||
      ('' as unknown as number),
    comment: claimItem?.comment || '',
    documentationFiles: claimItem?.documentationFiles || [],
  };

  if (claimItem?.claimType) {
    formInitialValues.claimType = claimItem.claimType as ClaimItemFromValidationSchema['claimType'];
  }

  const formik = useFormik<Partial<ClaimItemFromValidationSchema>>({
    initialValues: formInitialValues,
    validationSchema: claimItemSchema,
    onSubmit: (values) => {
      onSubmit(values as ClaimItemFromValidationSchema);
      resetForm();
    },
    validateOnBlur: true,
  });

  const resetForm = () => {
    formik.resetForm({
      values: formInitialValues,
    });
  };

  return (
    <ClaimItemFormContext.Provider
      value={{ translations, locale, invoiceItem, claimItem, formik, resetForm }}
    >
      {children}
    </ClaimItemFormContext.Provider>
  );
};

export const useClaimItemFormContext = () => {
  return useContext(ClaimItemFormContext);
};
