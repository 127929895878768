import { useAssetToken } from '@lego/b2b-unicorn-data-access-layer/react';
import { useEffect, useState } from 'react';

export const useUrlTokenization = () => {
  const [assetUrl, setAssetUrl] = useState('');
  const [token, setToken] = useState('');
  const [tokenizedUrl, setTokenizedUrl] = useState('');
  const [error, setError] = useState(false);

  const [assetToken, { data: tokenResponse, error: tokenError }] = useAssetToken();

  const assetTokanizationHandler = (customerId: number, assetUid: string, assetUrl: string) => {
    setAssetUrl(assetUrl);
    assetToken({
      variables: { customerId, assetUid },
    });
  };

  useEffect(() => {
    if (tokenResponse) {
      setToken(encodeURIComponent(tokenResponse.getAssetToken.token));
    }
  }, [tokenResponse]);

  useEffect(() => {
    if (token && assetUrl) {
      setTokenizedUrl(`${assetUrl}?token=${token}`);
      setAssetUrl('');
    }
    if (!assetUrl || !token) {
      setTokenizedUrl('');
      setToken('');
    }
  }, [assetUrl, token]);

  useEffect(() => {
    if (tokenError) {
      setError(true);
    }
    setAssetUrl('');
  }, [tokenError]);

  return { assetTokanizationHandler, tokenizedUrl, error } as const;
};
