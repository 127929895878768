import type { SerializedStyles } from '@emotion/react';
import React from 'react';

import { Icon, IconType } from '../../../ui/Icon';

type CounterMinusButtonProps = {
  onClick: (e: React.UIEvent) => void;
  disabled: boolean;
  cssStyles: SerializedStyles;
};

export const CounterMinusButton: React.FC<CounterMinusButtonProps> = ({
  onClick,
  disabled,
  cssStyles,
}) => {
  return (
    <button
      css={cssStyles}
      onClick={onClick}
      aria-label="Subtract quantity of product."
      disabled={disabled}
      tabIndex={-1}
    >
      <Icon type={IconType.MINUS} />
    </button>
  );
};
