import React, { useEffect, useRef, useState } from 'react';

import { Input } from '../Input';

type CurrencyInputProps = Omit<React.ComponentProps<typeof Input>, 'value' | 'onChange'> & {
  value: number;
  onChange: (value: number) => void;

  currency: string;
  locale: string;
};

const simpleCurrencyFormatter = (input: Intl.NumberFormatPart[]) => {
  return input
    .map(({ type, value }) => {
      switch (type) {
        case 'integer':
        case 'decimal':
        case 'fraction':
          return value;
        default:
          return '';
      }
    })
    .join('');
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  currency,
  locale,
  ...restProps
}) => {
  const [intl] = useState(new Intl.NumberFormat(locale, { style: 'currency', currency }));
  const [localeDecimalChar] = useState(() => {
    return intl.formatToParts(1234.56).find((part) => part.type === 'decimal')?.value || '.';
  });
  const [valueCleanerRegexp] = useState(new RegExp(`[^0-9${localeDecimalChar}]`, 'g'));
  const [formattedValue, setFormattedValue] = useState<string>(
    simpleCurrencyFormatter(intl.formatToParts(value))
  );
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: rawInput, selectionStart } = event.target;
    const cleanedInput = rawInput.replace(valueCleanerRegexp, '');
    const parsedValue = parseFloat(cleanedInput.replace(localeDecimalChar, '.'));

    setFormattedValue(simpleCurrencyFormatter(intl.formatToParts(parsedValue)));
    setCursorPosition(selectionStart);
    onChange(parsedValue);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!inputRef.current || event.currentTarget.selectionStart === null) {
      return;
    }

    const { key } = event;
    const charAfterCursor = inputRef.current.value[event.currentTarget.selectionStart];
    const charBeforeCursor = inputRef.current.value[event.currentTarget.selectionStart - 1];

    switch (key) {
      case 'Delete':
        if (charAfterCursor === localeDecimalChar) {
          event.preventDefault();
          setCursorPosition(event.currentTarget.selectionStart + 1);
        }
        break;
      case 'Backspace':
        if (charBeforeCursor === localeDecimalChar) {
          event.preventDefault();
          setCursorPosition(event.currentTarget.selectionStart - 1);
        }
        break;
    }

    if (
      event.currentTarget.selectionStart === 0 &&
      inputRef.current.value[0] === '0' &&
      '0123456789'.includes(key)
    ) {
      event.preventDefault();
      const nextValue = inputRef.current.value.replace(inputRef.current.value.charAt(0), key);
      const fakeChangeEvent = {
        target: {
          value: nextValue,
          selectionStart: event.currentTarget.selectionStart + 1,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      handleChange(fakeChangeEvent);
    }
  };

  useEffect(() => {
    const nextFormattedValue = simpleCurrencyFormatter(intl.formatToParts(value));
    if (nextFormattedValue !== formattedValue) {
      setFormattedValue(nextFormattedValue);
    }
  }, [value, formattedValue, intl]);

  useEffect(() => {
    if (cursorPosition !== null && inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Input
      {...restProps}
      inputRef={inputRef}
      leftAddon={currency}
      value={formattedValue}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
    />
  );
};
