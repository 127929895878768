import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { css, Global } from '@emotion/react';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

import SearchBox from './SearchBox';
import { SearchTranslationsContext, CUSTOM_BREAKPOINT_PIXELS } from './searchCommons';

const searchFormWrapperStyle = css({
  display: 'block',
  height: baseSpacing * 5 + 2,
  zIndex: 450,
});

const nonScrollableBodyWhenMobileOpenedStyle = css`
  @media (max-width: ${CUSTOM_BREAKPOINT_PIXELS - 1}px) {
    html,
    body {
      height: 100vh;
      overflow: hidden;
    }
  }
`;

const SearchProducts = ({
  translations,
  searchFunction,
  customerId,
  fetchProductImageUrl,
  locale,
}) => {
  const [showSearchBox, setShowSearchBox] = useState(false);

  const onSearchFocusedHandler = () => {
    setShowSearchBox(true);
  };

  const closeSearchHandler = () => {
    setShowSearchBox(false);
  };

  return (
    <SearchTranslationsContext.Provider value={translations}>
      <div css={searchFormWrapperStyle}>
        <SearchBox
          searchFunction={searchFunction}
          customerId={customerId}
          fetchProductImageUrl={fetchProductImageUrl}
          locale={locale}
          onSearchFocus={onSearchFocusedHandler}
          closeSearch={closeSearchHandler}
          searchShown={showSearchBox}
        />
        {showSearchBox && <Global styles={nonScrollableBodyWhenMobileOpenedStyle} />}
      </div>
    </SearchTranslationsContext.Provider>
  );
};

SearchProducts.propTypes = {
  searchFunction: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    search_showing_results: PropTypes.string.isRequired,
    search_loading: PropTypes.string.isRequired,
    search_min_chars: PropTypes.string.isRequired,
    search_no_results: PropTypes.string.isRequired,
    search_header_and_placeholder: PropTypes.string.isRequired,
    search_empty_mobile_hint: PropTypes.string.isRequired,
    search_error: PropTypes.string.isRequired,
  }),
  customerId: PropTypes.number,
  fetchProductImageUrl: PropTypes.func,
  locale: PropTypes.string,
};

export default SearchProducts;
