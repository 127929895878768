const handleAuthorisedFetch = async (src, token, customerId) => {
  const response = await fetch(src, {
    headers: {
      Authorization: `Bearer ${token}`,
      'lego-b2b-customer-id': customerId,
    },
  });
  if (response.status !== 200) {
    return null;
  }
  return response;
};

export default handleAuthorisedFetch;
