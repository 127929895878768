import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Input } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

import { bigTextStyle } from '../../../common/styles/checkoutFlowStyles';
import { inputContainerStyle } from '../../../common/styles/inputStyles';

const labelStyle = css({
  marginBottom: baseSpacing,
});

interface IOrderName {
  orderName?: string;
  setOrderName: (value: string) => void;
}

const OrderName = ({ orderName, setOrderName }: IOrderName) => {
  const { order_name, order_name_select_placeholder } = useLabels();

  return (
    <div css={inputContainerStyle}>
      <h4 css={bigTextStyle}>{order_name}</h4>
      <div css={labelStyle}>{order_name_select_placeholder}</div>
      <Input
        onChange={(e) => setOrderName(e.target.value)}
        value={orderName}
        // maxLength in SAP is 35 chars
        maxLength={35}
      />
    </div>
  );
};

export default OrderName;
