import { css } from '@emotion/react';
import React from 'react';

type FlexInput = {
  alignItems?: React.CSSProperties['alignItems'];
  flexDirection?: React.CSSProperties['flexDirection'];
  justifyContent?: React.CSSProperties['justifyContent'];
  gap?: number;
};
type MediaInput<T> = {
  [media: string]: T;
};
type FlexBoxProps = {
  children: React.ReactNode;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  flexDirection?: React.CSSProperties['flexDirection'];
  gap?: React.CSSProperties['gap'];
  flexMedium?: MediaInput<FlexInput>;

  fullWidth?: boolean;
  height?: React.CSSProperties['height'];
  className?: string;
};

const bodyStyle = (fullWidth: boolean = false, height?: React.CSSProperties['height']) => ({
  width: fullWidth ? '100%' : 'auto',
  height,
});
const flexStyle = (
  alignItems?: React.CSSProperties['alignItems'],
  justifyContent?: React.CSSProperties['justifyContent'],
  flexDirection?: React.CSSProperties['flexDirection'],
  gap?: React.CSSProperties['gap']
) =>
  css({
    display: 'flex',
    alignItems,
    justifyContent,
    flexDirection,
    gap,
  });
const flexMediumStyle = (flex?: MediaInput<FlexInput>) => css(flex);

export const FlexBox: React.FC<FlexBoxProps> = ({
  children,
  fullWidth,
  height,
  alignItems,
  justifyContent,
  flexDirection,
  gap,
  flexMedium,
  className,
}) => {
  return (
    <div
      css={[
        bodyStyle(fullWidth, height),
        flexStyle(alignItems, justifyContent, flexDirection, gap),
        flexMedium && flexMediumStyle(flexMedium),
      ]}
      className={className}
    >
      {children}
    </div>
  );
};
