import type { DataAccessLayer } from '@lego/b2b-unicorn-data-access-layer';
import type { FileUpload } from '@lego/b2b-unicorn-shared/components/FileUpload';
import React from 'react';

export const getUploadUrl =
  (
    dataAccessLayerInstance: DataAccessLayer,
    customerId: number,
    invoiceNumber: number
  ): React.ComponentProps<typeof FileUpload>['getFileUploadUrl'] =>
  async (file, metadata) => {
    const [mutate] = dataAccessLayerInstance.claims.createClaimFileResource(customerId);
    const { data } = await mutate({
      input: {
        fileName: file.name,
        invoiceNumber,
        md5Checksum: metadata['Content-MD5'],
      },
    });

    if (!data) {
      throw new Error('Failed to get upload URL');
    }

    return {
      url: data.createClaimFileResource.uri,
      metadata: {
        uuid: data.createClaimFileResource.uuid,
      },
    };
  };
