import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Button, ButtonType, Modal, ModalActions, ModalHeader } from '@lego/b2b-unicorn-shared/ui';
import React from 'react';

type ResetActionWarningTranslations = {
  header: string;
  content: string;
};
type ResetActionWarningProps = {
  open: boolean;
  translations: ResetActionWarningTranslations;
  onClose: () => void;
  onResetAction: () => void;
};

export const ResetActionWarning: React.FC<ResetActionWarningProps> = ({
  open,
  translations,
  onClose,
  onResetAction,
}) => {
  const { button_no, button_yes } = useLabels();
  return (
    <Modal isOpen={open}>
      <ModalHeader>{translations.header}</ModalHeader>
      {translations.content}
      <ModalActions>
        <Button
          type={ButtonType.SECONDARY}
          size="small"
          onClick={onClose}
        >
          {button_no}
        </Button>
        <Button
          size="small"
          onClick={onResetAction}
        >
          {button_yes}
        </Button>
      </ModalActions>
    </Modal>
  );
};
