import { ClaimItem, InvoiceOrderItem } from '@lego/b2b-unicorn-shared/components/Claim';
import { ClaimItemFromValidationSchema } from '@lego/b2b-unicorn-shared/components/Claim/validation';

const calculateTotalClaimPrice = (
  formValues: ClaimItemFromValidationSchema,
  invoiceItem: InvoiceOrderItem
) => {
  if (formValues.expectedPrice && invoiceItem.piecePrice.__typename === 'Price') {
    return (
      (invoiceItem.piecePrice.estimatedNetInvoicedPrice - formValues.expectedPrice) *
      invoiceItem.pieces
    );
  }
  if (
    formValues.claimType === 'OVER_UNDER_SUB' &&
    formValues.pieces &&
    invoiceItem.piecePrice.__typename === 'Price'
  ) {
    return invoiceItem.piecePrice.estimatedNetInvoicedPrice * formValues.pieces * -1;
  }

  if (formValues.pieces && invoiceItem.piecePrice.__typename === 'Price') {
    return invoiceItem.piecePrice.estimatedNetInvoicedPrice * formValues.pieces;
  }
  return 0;
};

export const mapClaimItemFormValuesToClaimItem = (
  formValues: ClaimItemFromValidationSchema,
  invoiceItem: InvoiceOrderItem
): ClaimItem => {
  const totalClaimPrice = calculateTotalClaimPrice(formValues, invoiceItem);
  return {
    materialId: invoiceItem.materialId,
    orderNumber: invoiceItem.orderNumber,
    invoiceLineNumber: invoiceItem.invoiceLineNumber,
    name: invoiceItem.name,
    claimType: formValues.claimType,
    pieces: formValues.pieces || null,
    piecePrice: invoiceItem.piecePrice,
    expectedPrice: formValues.expectedPrice
      ? {
          estimatedNetInvoicedPrice: formValues.expectedPrice || 0,
          currency: invoiceItem.piecePrice.currency,
          __typename: 'Price',
        }
      : null,
    totalClaimPrice: {
      estimatedNetInvoicedPrice: totalClaimPrice,
      currency: invoiceItem.piecePrice.currency,
      __typename: 'Price',
    },
    comment: formValues.comment || null,
    documentationFiles: formValues.documentationFiles || [],
  };
};
