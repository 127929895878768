import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing } from '@lego/b2b-unicorn-ui-constants';

export const hintStyle = css({
  color: designToken.error.default,
  margin: 0,
  marginTop: baseSpacing,
  lineHeight: 1.5,
  top: 76,
  zIndex: -1,
});
