import { BehaviorSubject, interval, map, Subscription, tap } from 'rxjs';

import { FileUploadItem, FileUploadItemStatus } from './FileUploadItem';

// Currently only image/jpeg is supported
type ImageConversionOptions = {
  mimeType: 'image/jpeg';
  quality: number;
  maxResolution: number;
};

type FileUploadServiceOptions = {
  /*
   * Accepted file types that can be uploaded
   */
  acceptedFileTypes?: string[];

  /*
   * Convert mimetype to another mimetype, will throw an error if the mimetype is not in the acceptedFileTypes and conversion is not possible
   *
   * e.g. { 'image/*': 'image/jpeg' }
   */
  convert: Record<string, ImageConversionOptions>;

  /*
   * Maximum file size in bytes
   */
  maxSize: number;

  /*
   * Maximum number of parallel uploads
   */
  maxParallelProcessing: number;
};

const instances: Map<string, FileUploadService> = new Map();

export class FileUploadService {
  private readonly name: string;
  private options: FileUploadServiceOptions;
  public files = new BehaviorSubject<FileUploadItem[]>([]);
  private _running = 0;
  private _isDirty = false;

  constructor(name: string, options: FileUploadServiceOptions) {
    this.name = name;
    this.options = options;
  }

  static createInstance(name: string, options: FileUploadServiceOptions) {
    if (instances.has(name)) {
      // eslint-disable-next-line no-console
      console.warn(`FileUploadService instance with name ${name} already existed, recreating...`);
    }

    const instance = new FileUploadService(name, options);
    instances.set(name, instance);
    return instance;
  }

  static getInstance(name: string) {
    if (!instances.has(name)) {
      throw new Error(`FileUploadService instance with name ${name} does not exist`);
    }
    return instances.get(name)!;
  }

  public filesByStack(stack: string) {
    return this.files.pipe(
      // tap((files) => {
      //   // eslint-disable-next-line no-console
      //   console.log(files);
      // }),
      map((files) => files.filter((file) => file.stack === stack))
    );
  }

  public addFile(
    uniqueHashValue: string,
    file: File,
    setUploadUrl: ConstructorParameters<typeof FileUploadItem>[2],
    stack?: string
  ) {
    const fileUploadItem = new FileUploadItem(uniqueHashValue, file, setUploadUrl, stack);

    const foundFile = this.files.value.find(
      (f) => f.uniqueHashValue === uniqueHashValue && f.stack === stack
    );
    // If file already in queue, reset state if it's not in success state or below
    if (foundFile) {
      if (foundFile.status > FileUploadItemStatus.Success) {
        foundFile.setStatus(FileUploadItemStatus.Idle);
      }
    } else {
      this.files.next([...this.files.value, fileUploadItem]);
    }
  }

  public removeFile(fileItem: FileUploadItem) {
    this.files.next(this.files.value.filter((f) => f !== fileItem));
  }

  public removeFilesByStack(stack: string) {
    this.files.next(this.files.value.filter((f) => f.stack !== stack));
  }

  public run(): Subscription {
    return interval(200)
      .pipe(
        tap(async () => {
          if (this._running > 0) {
            return;
          }

          // Process idle files
          const idleFiles = this.files.value.filter(
            (file) => file.status === FileUploadItemStatus.Idle
          );
          for (const idleFile of idleFiles) {
            if (this._running >= this.options.maxParallelProcessing) {
              break;
            }
            this._running++;
            idleFile
              .mimeType()
              .then((fileMimeType) => {
                const wildcardMimeType = fileMimeType.split('/')[0] + '/*';
                if (
                  this.options.acceptedFileTypes?.filter(
                    (fileType) => fileType === fileMimeType || fileType === wildcardMimeType
                  ).length === 0
                ) {
                  idleFile.setStatus(FileUploadItemStatus.Unsupported);
                } else {
                  idleFile.setStatus(FileUploadItemStatus.Processing);
                }

                this._isDirty = true;
              })
              .finally(() => {
                this._running--;
              });
          }

          // Process processing files
          const processingFiles = this.files.value.filter(
            (file) => file.status === FileUploadItemStatus.Processing
          );
          for (const processingFile of processingFiles) {
            if (this._running >= this.options.maxParallelProcessing) {
              break;
            }
            this._running++;
            const fileMimeType = await processingFile.mimeType();
            const wildcardMimeType = fileMimeType.split('/')[0] + '/*';
            const conversionOptions =
              this.options.convert[fileMimeType] || this.options.convert[wildcardMimeType];
            if (conversionOptions) {
              if (fileMimeType !== conversionOptions.mimeType) {
                processingFile
                  .convertTo({
                    maxResolution: conversionOptions.maxResolution,
                    quality: conversionOptions.quality,
                    imageType: conversionOptions.mimeType,
                  })
                  .then(() => {
                    processingFile.setStatus(FileUploadItemStatus.Checksum);
                    this._isDirty = true;
                  })
                  .finally(() => {
                    this._running--;
                  });
              } else {
                processingFile.setStatus(FileUploadItemStatus.Checksum);
                this._running--;
              }
            } else {
              this._running--;
            }
          }

          // Process checksum files
          const checksumFiles = this.files.value.filter(
            (file) => file.status === FileUploadItemStatus.Checksum
          );
          for (const checksumFile of checksumFiles) {
            if (this._running >= this.options.maxParallelProcessing) {
              break;
            }
            this._running++;
            checksumFile
              .awsContentMd5()
              .then((checksum) => {
                checksumFile.setMetadata('Content-MD5', checksum);
                checksumFile.setStatus(FileUploadItemStatus.Uploading);
                this._isDirty = true;
              })
              .finally(() => {
                this._running--;
              });
          }

          // Process uploading files
          const uploadingFiles = this.files.value.filter(
            (file) => file.status === FileUploadItemStatus.Uploading
          );
          for (const uploadingFile of uploadingFiles) {
            if (this._running >= this.options.maxParallelProcessing) {
              break;
            }
            this._running++;
            uploadingFile
              .upload({
                'Content-MD5': uploadingFile.metadata['Content-MD5'],
              })
              .then(() => {
                uploadingFile.setStatus(FileUploadItemStatus.Success);
              })
              .catch(() => {
                uploadingFile.setStatus(FileUploadItemStatus.Failed);
              })
              .finally(() => {
                this._isDirty = true;
                this._running--;
              });
          }

          if (this._isDirty) {
            this.files.next([...this.files.value]);
            this._isDirty = false;
          }
        })
      )
      .subscribe();
  }
}
