import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { UpcomingDeliveriesFilters } from '@lego/b2b-unicorn-data-access-layer';
import { useUpcomingDeliveriesLiveQuery } from '@lego/b2b-unicorn-data-access-layer/react';
import { ClearAllTag, FilterTag } from '@lego/b2b-unicorn-ui-components';
import { Fragment } from 'react';

import LiveSearchField, {
  ILiveSearchResult,
} from '../../../components/LiveSearchField/LiveSearchField';
import SelectBox from '../../../components/SelectBox/SelectBox';
import useShipTos from '../../../hooks/useShipTos';
import { filterContainerStyle, tagContainerstyle } from '../../../styles/filtering';
import { FiltersInURL, HumanReadableDateRange } from '../../../utils/DateRanges';
import { reduceArrayToUniqueItemsByProperty } from '../../../utils/reduceArrayToUniqueItemsByProperty';

interface ISearchAndFilterHandlerProps {
  updateCallback: (model: FiltersInURL<UpcomingDeliveriesFilters> | undefined) => void;
  selectedFilters?: FiltersInURL<UpcomingDeliveriesFilters>;
  customerId: number;
  locale: string;
}

const DeliveriesSearchAndFilters = (props: ISearchAndFilterHandlerProps) => {
  const labelsContext = useLabels();
  const { getLabelByKey } = labelsContext;
  const [upcomingDeliveriesByLiveField] = useUpcomingDeliveriesLiveQuery();

  const shipTos = useShipTos(props.customerId);
  const shipToValues = shipTos.map((shipto) => ({
    displayText: `${shipto.id} ${shipto.city}`,
    value: shipto.id,
  }));

  const handleItemSearchQuery = (value: string) => {
    props.updateCallback({
      ...props.selectedFilters,
      itemQuery: value,
    });
  };

  const handleItemLiveSearch = async (itemQuery: string): Promise<ILiveSearchResult[]> => {
    if (itemQuery) {
      const liveSearchResult = upcomingDeliveriesByLiveField({
        variables: {
          customerId: props.customerId,
          filter: {
            itemQuery,
          },
        },
      }).then((result) => {
        // TODO error handling ???
        if (result.data) {
          const uniqueItems = reduceArrayToUniqueItemsByProperty(
            result.data.upcomingDeliveries.upcomingDeliveries,
            'itemNumber'
          );
          return uniqueItems.map((item) => ({
            displayElement: `${item.itemNumber} ${item.itemName}`,
            onSelectFunction: () => {
              handleItemSearchQuery(item.itemName);
            },
          }));
        } else {
          return [];
        }
      });
      return liveSearchResult;
    } else {
      return [];
    }
  };

  const handleOrderSearchQuery = (value: string) => {
    props.updateCallback({
      ...props.selectedFilters,
      orderQuery: value,
    });
  };

  const handleOrderLiveSearch = async (orderQuery: string): Promise<ILiveSearchResult[]> => {
    if (orderQuery) {
      const liveSearchResult = upcomingDeliveriesByLiveField({
        variables: {
          customerId: props.customerId,
          filter: {
            orderQuery,
          },
        },
      }).then((result) => {
        // TODO error handling ???
        if (result.data) {
          const uniqueItems = reduceArrayToUniqueItemsByProperty(
            result.data.upcomingDeliveries.upcomingDeliveries,
            'orderNumber'
          );
          return uniqueItems.map((item) => ({
            displayElement: `${item.orderNumber} ${item.orderName}`,
            onSelectFunction: () => {
              handleOrderSearchQuery(item.orderNumber.toString());
            },
          }));
        } else {
          return [];
        }
      });
      return liveSearchResult;
    } else {
      return [];
    }
  };

  const handleShipToSelect = (value: number | number[]) => {
    if (Array.isArray(value)) {
      props.updateCallback({
        ...props.selectedFilters,
        shipToIds: value,
      });
    }
  };

  const handleDateRangeSelect = (dateRange: string | string[]) => {
    props.updateCallback({
      ...props.selectedFilters,
      dateRange: dateRange as HumanReadableDateRange,
    });
  };

  const removeAllTags = () => {
    props.updateCallback(
      props.selectedFilters?.sort ? { sort: props.selectedFilters.sort } : undefined
    );
  };

  const removeItemQueryTag = () => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.itemQuery = '';
    props.updateCallback(updatedFilters);
  };

  const removeOrderQueryTag = () => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.orderQuery = '';
    props.updateCallback(updatedFilters);
  };

  const removeShipToTag = (value: number) => {
    const updatedFilters = { ...props.selectedFilters };
    updatedFilters.shipToIds = updatedFilters?.shipToIds?.filter((id) => id !== value);
    props.updateCallback(updatedFilters);
  };

  const removeDateRangeTag = () => {
    const updatedFilters = { ...props.selectedFilters };
    delete updatedFilters.dateRange;
    props.updateCallback(updatedFilters);
  };

  const tagCount =
    (props.selectedFilters?.itemQuery ? 1 : 0) +
    (props.selectedFilters?.orderQuery ? 1 : 0) +
    (props.selectedFilters?.shipToIds ? props.selectedFilters.shipToIds.length : 0) +
    (props.selectedFilters?.dateRange ? 1 : 0);

  return (
    <Fragment>
      <div css={filterContainerStyle}>
        <div>
          <LiveSearchField
            placeholder={`${labelsContext.item_id} / ${labelsContext.name}`}
            searchValueSubmitHandler={handleItemSearchQuery}
            clearAfterSubmit={true}
            liveSearchFunction={handleItemLiveSearch}
            delaySeconds={0.5}
            labels={{
              search_loading: labelsContext.search_loading,
              search_min_chars: labelsContext.search_min_chars,
              search_no_results: labelsContext.search_no_results,
            }}
          />
        </div>
        <div>
          <LiveSearchField
            placeholder={`${labelsContext.order_number} / ${labelsContext.order_name}`}
            searchValueSubmitHandler={handleOrderSearchQuery}
            clearAfterSubmit={true}
            liveSearchFunction={handleOrderLiveSearch}
            delaySeconds={0.5}
            labels={{
              search_loading: labelsContext.search_loading,
              search_min_chars: labelsContext.search_min_chars,
              search_no_results: labelsContext.search_no_results,
            }}
          />
        </div>
        <SelectBox
          label={labelsContext.shipping_address}
          values={shipToValues}
          selectedValues={props.selectedFilters?.shipToIds || []}
          optionsChangeHandler={handleShipToSelect}
          allowMultiple={true}
        />
        <SelectBox
          label={labelsContext.delivery_date_expected}
          values={Object.values(HumanReadableDateRange).map((dateRange) => ({
            displayText: getLabelByKey(dateRange.toLowerCase()),
            value: dateRange,
          }))}
          selectedValues={props.selectedFilters?.dateRange}
          optionsChangeHandler={handleDateRangeSelect}
          allowMultiple={true}
        />
      </div>
      <div css={tagCount > 0 ? tagContainerstyle : null}>
        {tagCount > 1 && (
          <ClearAllTag
            onClickHandler={removeAllTags}
            text={labelsContext.filter_clear_all}
          />
        )}
        {props.selectedFilters?.itemQuery && (
          <FilterTag
            onClickHandler={removeItemQueryTag}
            text={props.selectedFilters.itemQuery}
            option={'itemQuery'}
            value={props.selectedFilters.itemQuery}
          />
        )}
        {props.selectedFilters?.orderQuery && (
          <FilterTag
            onClickHandler={removeOrderQueryTag}
            text={props.selectedFilters.orderQuery}
            option={'itemQuery'}
            value={props.selectedFilters.orderQuery}
          />
        )}
        {shipToValues
          .filter((shipto) => props.selectedFilters?.shipToIds?.includes(shipto.value))
          .map((shipto) => (
            <FilterTag
              onClickHandler={() => {
                removeShipToTag(shipto.value);
              }}
              text={shipto.displayText}
              option={'shipToIds'}
              value={shipto.value}
              key={`tag-shipto-${shipto.value}`}
            />
          ))}
        {props.selectedFilters?.dateRange && (
          <FilterTag
            onClickHandler={removeDateRangeTag}
            text={getLabelByKey(props.selectedFilters.dateRange.toLowerCase())}
            option={'dateRange'}
            value={props.selectedFilters.dateRange}
          />
        )}
      </div>
    </Fragment>
  );
};

export default DeliveriesSearchAndFilters;
