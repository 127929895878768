import type { TypePolicies } from '@apollo/client/cache/inmemory/policies';
import { deepEqual } from 'fast-equals';
import { cloneDeep } from 'lodash';

import {
  OrderQuery,
  OrderQueryVariables,
  OrdersProductsQuery,
  OrdersProductsQueryVariables,
  OrdersQuery,
  OrdersQueryVariables,
  PdfOrderQuery,
  PdfOrderQueryVariables,
  ValidateItemsQuery,
  ValidateItemsQueryVariables,
} from '../../generated-types/graphql';
import { CartReferenceInput } from '../../generated-types/types';
import { ExtractElementType } from '../../utils/TypeScriptHelpers';
import { ContextAbstract } from '../ContextAbstract';
import { ORDER } from './queries/order';
import { ORDERS } from './queries/orders';
import { ORDERS_PRODUCTS } from './queries/ordersProducts';
import { PDF_ORDER } from './queries/pdfOrder';
import { VALIDATE_ITEMS } from './queries/validateItems';

export type OrdersFilters = NonNullable<OrdersQueryVariables['filter']>;

export type OrdersList = OrdersQuery['orders'];
export type OrdersItem = ExtractElementType<NonNullable<OrdersQuery['orders']>['orders']>;
export type Order = NonNullable<OrderQuery['order']>;
export type OrderItem = ExtractElementType<NonNullable<OrderQuery['order']>['items']>;
export type ValidItem = ExtractElementType<
  NonNullable<NonNullable<ValidateItemsQuery['validateProducts']>['validItems']>
>;
export type InvalidItem = ExtractElementType<
  NonNullable<NonNullable<ValidateItemsQuery['validateProducts']>['invalidItems']>
>;

export class OrdersDataContext extends ContextAbstract {
  static TypePolicies: TypePolicies = {
    Query: {
      fields: {
        orders: {
          keyArgs: ['customerId'],
          merge(existing: OrdersList | undefined, incoming: OrdersList, { variables }) {
            const merged = existing ? cloneDeep(existing) : cloneDeep(incoming);

            if (incoming && !deepEqual(merged, incoming)) {
              const typedVariables = variables as OrdersQueryVariables;
              if (
                typedVariables &&
                typedVariables.filter &&
                typedVariables.filter.offset !== null
              ) {
                const { offset = 0 } = typedVariables.filter;
                for (let i = 0; i < incoming.orders.length; ++i) {
                  merged.orders[offset + i] = incoming.orders[i];
                }
              }
            }

            return merged;
          },
          read(existing: OrdersList | undefined) {
            return existing;
          },
        },
      },
    },
  };

  public getAll(customerId: number, filter: OrdersFilters = { limit: 0, offset: 0 }) {
    const observableKey = `orders-${customerId}`;

    return this.queryObservable<OrdersQuery, OrdersQueryVariables, OrdersQueryVariables>(
      observableKey,
      ORDERS,
      {
        customerId,
        filter,
      },
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public products(customerId: number) {
    const observableKey = `getProducts-${customerId}`;

    return this.queryObservable<
      OrdersProductsQuery,
      OrdersProductsQueryVariables,
      OrdersProductsQueryVariables
    >(observableKey, ORDERS_PRODUCTS, {
      customerId,
    });
  }

  public getById(orderNumber: number, customerId: number) {
    const observableKey = `getOrder-${orderNumber}-${customerId}`;

    return this.queryObservable<OrderQuery, OrderQueryVariables>(observableKey, ORDER, {
      orderNumber,
      customerId,
    });
  }

  public getPdfOrder(orderNumber: number, customerId: number) {
    return this._apolloClient.query<PdfOrderQuery, PdfOrderQueryVariables>({
      query: PDF_ORDER,
      variables: {
        orderNumber,
        customerId,
      },
      fetchPolicy: 'no-cache',
    });
  }

  public getPdfOrderLazy() {
    const query = PDF_ORDER;
    const execute = (orderNumber: number, customerId: number) =>
      this.getPdfOrder(orderNumber, customerId);

    return {
      execute,
      query,
    };
  }

  public validateItems(customerId: number, cartReference: CartReferenceInput) {
    const observableKey = `validateItems-${customerId}-${cartReference.cartType}`;
    return this.mutationObservable<ValidateItemsQuery, ValidateItemsQueryVariables>(
      observableKey,
      VALIDATE_ITEMS
    );
  }
}
