import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Date } from '@lego/b2b-unicorn-shared/components/Date';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { PendingAnimation } from '@lego/b2b-unicorn-ui-components';
import { colors } from '@lego/b2b-unicorn-ui-constants';
import { useUrlTokenization } from '@lego/b2b-unicorn-ui-utils';
import { useEffect, useState } from 'react';

import {
  disabledButtonStyle,
  infoListItemHoverStyle,
  infoListItemStyle,
} from '../../../styles/infoListStyles';

interface IInfoListFile {
  title: string;
  updated?: string;
  size: string;
  fileType: string;
  url: string;
  assetUid?: string | null;
  customerId: number;
}

const supportedFileFormats = {
  excel: ['xlsx', 'xls'],
  pdf: ['pdf'],
  word: ['doc', 'docm', 'docx'],
  ppt: ['ppt', 'pptx'],
};

const getIconType = (fileType: string) => {
  if (supportedFileFormats.excel.includes(fileType)) return IconType.FILE_XLS;
  if (supportedFileFormats.pdf.includes(fileType)) return IconType.FILE_PDF;
  if (supportedFileFormats.ppt.includes(fileType)) return IconType.FILE_PPT;
  if (supportedFileFormats.word.includes(fileType)) return IconType.FILE_DOC;
  return IconType.FILE;
};

const InfoListFile = ({
  title,
  updated,
  size,
  fileType,
  url,
  assetUid,
  customerId,
}: IInfoListFile) => {
  const { locale } = useSelectedCustomer();
  const [pending, setPending] = useState(false);

  const { button_pending } = useLabels();

  const { assetTokanizationHandler, tokenizedUrl, error } = useUrlTokenization();

  const iconType = getIconType(fileType);

  useEffect(() => {
    if (tokenizedUrl) {
      window.open(tokenizedUrl, '_blank');
      setPending(false);
    }
  }, [tokenizedUrl]);

  useEffect(() => {
    error && setPending(false);
  }, [error]);

  const handleDownload = () => {
    setPending(true);
    if (url && customerId && assetUid) {
      assetTokanizationHandler(customerId, assetUid, url);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      e.preventDefault();
      handleDownload();
    }
  };

  const renderPendingButton = () => (
    <button
      css={[infoListItemStyle, disabledButtonStyle(pending)]}
      disabled
    >
      <PendingAnimation fillColor={colors.klik.lightBlue200} />
      <span>{button_pending}</span>
    </button>
  );

  const renderActiveButton = () => (
    <button
      css={[infoListItemStyle, infoListItemHoverStyle, disabledButtonStyle(pending)]}
      onKeyDown={onKeyDownHandler}
      onClick={handleDownload}
      disabled={!assetUid}
    >
      <Icon type={iconType} />
      <span>
        {title} ({size}MB) - <Date locale={locale}>{updated}</Date>
      </span>
    </button>
  );

  return pending ? renderPendingButton() : renderActiveButton();
};

export default InfoListFile;
