import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, font } from '../theme';

const modalHeaderStyle = css({
  fontSize: font.size.large,
  fontWeight: font.weight.light,
  lineHeight: 1.25,
  marginBottom: baseSpacing,
  display: 'inline-flex',
  '> svg': {
    height: 36,
    width: 36,
    marginRight: baseSpacing,
    transform: 'translateY(-2px)',
  },
});

type ModalHeaderProps = {
  children: React.ReactNode;
  subHeader?: React.ReactNode;
};
export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, subHeader }) => {
  return (
    <>
      <div css={modalHeaderStyle}>{children}</div>
      {subHeader}
    </>
  );
};
