import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { Interpolation } from '@emotion/styled';
import { tokens } from '@lego/core-colors';
import { nanoid } from 'nanoid';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { FormControlInvalid } from '../FormControl';
import { baseSpacing, designToken, font } from '../theme';

const inputStyle = (textAlign: 'left' | 'right', prefixWidth?: number, suffixWidth?: number) =>
  css({
    width: `100%`,
    minWidth: 0,
    outline: `transparent solid 2px`,
    outlineOffset: 2,
    position: `relative`,
    appearance: `none`,
    transition: `all 0.2s ease 0s`,
    borderRadius: `0.25rem`,
    fontSize: font.size.small,
    lineHeight: font.lineHeight,
    paddingInlineStart: prefixWidth ? baseSpacing * 2 + prefixWidth : baseSpacing,
    paddingInlineEnd: suffixWidth ? baseSpacing * 2 + suffixWidth : baseSpacing,
    height: 40,
    borderWidth: 1,
    borderStyle: `solid`,
    borderColor: designToken.border.default,
    color: designToken.text.muted,
    background: tokens.color.neutral.white,
    textAlign: textAlign,

    '&[aria-invalid="true"]': {
      borderColor: designToken.border.error,
      boxShadow: `${designToken.border.error} 0 0 0 1px`,
    },

    '&:focus': {
      borderColor: designToken.border.focus,
      boxShadow: `${designToken.border.focus} 0 0 0 1px`,
    },

    '&:hover:not(:focus,[aria-invalid="true"])': {
      borderColor: tokens.color.core.slate['300'],
      boxShadow: `${tokens.color.core.slate['300']} 0 0 0 1px`,
    },

    '&:disabled': {
      transition: 'none',
      backgroundColor: tokens.color.core.slate['50'],
      borderColor: designToken.border.subdued,
      color: designToken.text.disabled,
      userSelect: `none`,
    },
  });

const inputWrapper = css({
  position: 'relative',
});

const fixStyle = (width?: number) =>
  css({
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    fontSize: font.size.small,
    height: font.size.small * 3 - 2,
    width: width ? baseSpacing * 2 + width : 'auto',

    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '1em',
      height: '1em',
      fontSize: font.size.small * 1.375,
    },
  });

const prefixWrapper = (width?: number, variant?: 'addon' | 'element') =>
  css(fixStyle(width), {
    left: 0,
    borderRightStyle: 'solid',
    borderRightWidth: variant === 'addon' ? 1 : 0,
    borderRightColor: tokens.color.core.slate['200'],
    color: variant === 'addon' ? tokens.color.core.slate['900'] : tokens.color.core.slate['600'],
    fill: variant === 'addon' ? tokens.color.core.slate['900'] : tokens.color.core.slate['600'],
  });

const suffixWrapper = (width?: number, variant?: 'addon' | 'element') =>
  css(fixStyle(width), {
    right: 0,
    borderLeftStyle: 'solid',
    borderLeftWidth: variant === 'addon' ? 1 : 0,
    borderLeftColor: tokens.color.core.slate['200'],
    color: variant === 'addon' ? tokens.color.core.slate['900'] : tokens.color.core.slate['600'],
    fill: variant === 'addon' ? tokens.color.core.slate['900'] : tokens.color.core.slate['600'],
  });

type InputProps = {
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  inputRef?: ReturnType<typeof useRef<HTMLInputElement>>;
  maxLength?: number;
  textAlign?: 'left' | 'right';
  disabled?: boolean;
  name?: string;

  // Form control props
  // Marks the input field as invalid
  isInvalid?: boolean;
  // If invalid, the message to display
  invalidMessage?: string;
};
type InputCleanProps = InputProps & {
  rightElement?: never;
  leftElement?: never;
  rightAddon?: never;
  leftAddon?: never;
};
type LeftAddonProps = {
  leftAddon?: React.ReactNode;
  leftElement?: never;
};
type LeftElementProps = {
  leftAddon?: never;
  leftElement?: React.ReactNode;
};
type LeftCleanProps = {
  leftAddon?: never;
  leftElement?: never;
};
type RightAddonProps = {
  rightAddon?: React.ReactNode;
  rightElement?: never;
};
type RightElementProps = {
  rightAddon?: never;
  rightElement?: React.ReactNode;
};
type RightCleanProps = {
  rightAddon?: never;
  rightElement?: never;
};

type LeftProps = LeftAddonProps | LeftElementProps | LeftCleanProps;
type RightProps = RightAddonProps | RightElementProps | RightCleanProps;
type InputWithLeftProps = InputProps & LeftProps & RightCleanProps;
type InputWithRightProps = InputProps & RightProps & LeftCleanProps;
type InputWithBothProps = InputProps & LeftProps & RightProps;

type InputConditionalProps =
  | InputCleanProps
  | InputWithLeftProps
  | InputWithRightProps
  | InputWithBothProps;

export const Input: React.FC<InputConditionalProps> = ({
  name,
  placeholder,
  rightElement,
  leftElement,
  rightAddon,
  leftAddon,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  value,
  inputRef,
  maxLength,
  onClick,
  disabled = false,
  isInvalid = false,
  invalidMessage,
  textAlign = 'left',
}) => {
  const prefixRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [suffixWidth, setSuffixWidth] = useState(0);
  // Generate a unique id for this input element, prepend the id with 'a' to ensure it's a valid id
  const [inputId] = useState(() => `a${nanoid(10)}`);

  useLayoutEffect(() => {
    if (!suffixRef.current && !prefixRef.current) {
      return;
    }
    const suffixObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        if (width === 0) {
          return;
        }
        setSuffixWidth(width);
        suffixObserver.disconnect();
      }
    });
    const prefixObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        if (width === 0) {
          return;
        }
        setPrefixWidth(width);
        prefixObserver.disconnect();
      }
    });
    !!prefixRef.current && prefixObserver.observe(prefixRef.current);
    !!suffixRef.current && suffixObserver.observe(suffixRef.current);
  }, []);

  const inputProps = useMemo(() => {
    const props: React.ClassAttributes<HTMLInputElement> &
      React.InputHTMLAttributes<HTMLInputElement> & { css?: Interpolation<Theme> } = {
      css: inputStyle(textAlign, prefixWidth, suffixWidth),
      name,
      placeholder,
      onBlur,
      onFocus,
      onChange,
      value,
      maxLength,
      onClick,
      onKeyDown,
      disabled,
      'aria-invalid': isInvalid || undefined,
      'aria-describedby': isInvalid ? `${inputId}-invalid` : undefined,
    };
    if (inputRef) {
      Object.assign(props, {
        ref: inputRef,
      });
    }

    return props;
  }, [
    disabled,
    inputId,
    inputRef,
    isInvalid,
    maxLength,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    placeholder,
    prefixWidth,
    suffixWidth,
    textAlign,
    value,
  ]);

  return (
    <div
      css={inputWrapper}
      data-invalid={isInvalid || undefined}
    >
      {(rightElement || rightAddon) && (
        <div
          css={suffixWrapper(suffixWidth, rightAddon ? 'addon' : 'element')}
          ref={suffixRef}
        >
          {rightElement || rightAddon}
        </div>
      )}
      {(leftElement || leftAddon) && (
        <div
          css={prefixWrapper(prefixWidth, leftAddon ? 'addon' : 'element')}
          ref={prefixRef}
        >
          {leftElement || leftAddon}
        </div>
      )}
      <input {...inputProps} />
      {isInvalid && invalidMessage && (
        <FormControlInvalid
          invalidMessage={invalidMessage}
          ariaId={`${inputId}-invalid`}
        />
      )}
    </div>
  );
};
