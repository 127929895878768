import { css } from '@emotion/react';
import { font } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

import { RemovedItemsTableHeader } from './RemovedItemsTableHeader';

const tableContainerStyle = css({
  display: 'block',
  maxWidth: '100%',
  overflow: 'auto',
});

const tableStyle = css({
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  borderCollapse: 'collapse',
  fontSize: font.size.small,
});

const tableBodyStyle = css({
  overflowY: 'auto',
  display: 'block',
  tableLayout: 'auto',
  width: '100%',
  position: 'relative',
});

interface IRemovedItemsTable {
  headers?: React.ComponentProps<typeof RemovedItemsTableHeader>['columns'];
  children: React.ReactNode;
}

export const RemovedItemsTable = ({ children, headers }: IRemovedItemsTable) => {
  return (
    <div css={tableContainerStyle}>
      <table
        css={tableStyle}
        aria-label="table"
      >
        <tbody css={tableBodyStyle}>
          <RemovedItemsTableHeader columns={headers} />
          {children}
        </tbody>
      </table>
    </div>
  );
};
