import { gql } from '@apollo/client';

export const CHECKOUT_CART = gql`
  query checkoutCart(
    $customerId: Int!
    $cartReference: CartReferenceInput!
    $removeObsoleteItems: Boolean = true
  ) {
    getCart(
      customerId: $customerId
      removeObsoleteItems: $removeObsoleteItems
      cartReference: $cartReference
    ) {
      id
      items {
        ...cartItemWithOpenOrderInfo
      }
      removedItems @include(if: $removeObsoleteItems) {
        ...removedCartItem
      }
      deliveryDates {
        earliestDate
        latestDate
      }
      minimumOrderValue {
        amount
        currency
      }
      ...cartKey
    }
    getShipTos(customerId: $customerId) {
      id
      name
      city
      leadtimeInDays
    }
  }
`;
