import { ASSETS_URL } from '@lego/b2b-unicorn-bootstrap/constants';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { getContent } from '@lego/b2b-unicorn-ui-utils';
import { isAfter, parseISO } from 'date-fns';
import { useEffect } from 'react';

import NotificationBanner from './components/NotificationBanner';
import { useNotifications } from './NotificationsProvider';

const Banner = () => {
  const { banner, addBanner, removeBanner } = useNotifications();
  const selectedCustomer = useSelectedCustomer();

  useEffect(() => {
    if (selectedCustomer.id) {
      const getBanner = async () => {
        const fetchedBanner: typeof banner = await getContent(
          selectedCustomer.id,
          `${ASSETS_URL}/router/notification-banner`
        );

        if (fetchedBanner) {
          const currentRemovedBannerDate = sessionStorage.getItem('removedBannerDate');
          const isIncomingBannerNew = currentRemovedBannerDate
            ? isAfter(parseISO(fetchedBanner.updated), parseISO(currentRemovedBannerDate))
            : true;
          const { message, type, link, updated } = fetchedBanner;

          isIncomingBannerNew && addBanner({ message, type, link, updated });
        }
      };
      getBanner();
    }
  }, [addBanner, selectedCustomer]);

  return (
    banner && (
      <NotificationBanner
        message={banner.message}
        link={banner.link}
        type={banner.type}
        removeBannerHandler={() => removeBanner(banner.updated)}
      />
    )
  );
};

export default Banner;
