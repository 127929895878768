import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { Customer } from '@lego/b2b-unicorn-data-access-layer';
import { Icon, IconType, Input } from '@lego/b2b-unicorn-shared/ui';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

type SearchCustomerInputProps = {
  customers: Customer[];
  onChange: (customers: Customer[]) => void;
  searchInputRef: ReturnType<typeof useRef<HTMLInputElement>>;
  triggerClear?: boolean;
  onCleared?: () => void;
};
export const SearchCustomerInput: React.FC<SearchCustomerInputProps> = ({
  customers,
  onChange,
  searchInputRef,
  triggerClear,
  onCleared,
}) => {
  const { search_customer } = useLabels();

  const fuse = useMemo(() => {
    return new Fuse(customers, {
      threshold: 0.4,
      ignoreLocation: true,
      keys: ['name', 'city', 'id'],
    });
  }, [customers]);

  const handleOnFilterValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      if (newValue === '') {
        onChange(customers);
      } else {
        const fuseResults = fuse.search(newValue).map((r) => r.item);
        onChange(fuseResults);
      }
    },
    [customers, fuse, onChange]
  );

  useEffect(() => {
    if (triggerClear) {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
        handleOnFilterValueChange({
          target: {
            value: '',
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }

      onCleared && onCleared();
    }
  }, [handleOnFilterValueChange, onCleared, searchInputRef, triggerClear]);

  return (
    <Input
      placeholder={search_customer}
      rightElement={<Icon type={IconType.SEARCH} />}
      onChange={handleOnFilterValueChange}
      inputRef={searchInputRef}
    />
  );
};
