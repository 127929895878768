import type { SerializedStyles } from '@emotion/react';
import React from 'react';

import { Icon, IconType } from '../../../ui/Icon';

type CounterPlusButtonProps = {
  onClick: (e: React.UIEvent) => void;
  disabled: boolean;
  cssStyles: SerializedStyles;
};

export const CounterPlusButton: React.FC<CounterPlusButtonProps> = ({
  onClick,
  disabled,
  cssStyles,
}) => {
  return (
    <button
      css={cssStyles}
      onClick={onClick}
      aria-label="Add quantity of product."
      disabled={disabled}
      tabIndex={-1}
    >
      <Icon type={IconType.PLUS} />
    </button>
  );
};
