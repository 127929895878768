import { gql } from '@apollo/client';

const GET_SHIP_TOS = gql`
  query GetShipTos($customerId: Int!) {
    getShipTos(customerId: $customerId) {
      id
      name
      city
      leadtimeInDays
    }
  }
`;

export default GET_SHIP_TOS;
