import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { Button, ButtonType } from '@lego/b2b-unicorn-shared/ui';
import { useUrlTokenization } from '@lego/b2b-unicorn-ui-utils';
import React, { ReactNode, useEffect, useState } from 'react';

type ContentstackAssetProps = {
  url?: string | null;
  assetUid: string;
  type?: ButtonType;
  size?: 'tiny' | 'small' | 'medium' | 'large';
  children: ReactNode;
};

export const ContentstackAsset: React.FC<ContentstackAssetProps> = ({
  url,
  assetUid,
  type = ButtonType.GHOST_INVERTED,
  size = 'tiny',
  children,
}) => {
  const [pending, setPending] = useState(false);
  const { id } = useSelectedCustomer();

  const { assetTokanizationHandler, tokenizedUrl, error } = useUrlTokenization();

  useEffect(() => {
    if (tokenizedUrl) {
      window.open(tokenizedUrl, '_blank');
      setPending(false);
    }
  }, [tokenizedUrl]);

  useEffect(() => {
    error && setPending(false);
  }, [error]);
  const handleDownload = () => {
    setPending(true);
    if (url && assetUid) {
      assetTokanizationHandler(id, assetUid, url);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    if (e.key === KEYCODE_STRING.ENTER) {
      e.preventDefault();
      handleDownload();
    }
  };

  return (
    <Button
      onClick={handleDownload}
      onKeyDown={onKeyDownHandler}
      disabled={pending}
      type={type}
      size={size}
    >
      {children}
    </Button>
  );
};
