import { ApolloProvider } from '@apollo/client';
import { FunctionComponent, ReactNode } from 'react';

import apolloClient, { IAppSyncLinkConfig } from '../utils/ApolloClient';

interface IApolloClientProvider {
  appSyncLinkConfig: IAppSyncLinkConfig;
  children: ReactNode;
}

const ApolloClientProvider: FunctionComponent<IApolloClientProvider> = ({
  children,
  appSyncLinkConfig,
}) => {
  const client = apolloClient(appSyncLinkConfig);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
