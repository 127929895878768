import React from 'react';

import { FlexBox } from '../FlexBox';
import { Icon, IconType } from '../Icon';
import { designToken } from '../theme';
import { Typography } from '../Typography';

type FormControlInvalidProps = {
  // The id that should be used for the invalid message element, the ariaId should match the aria-describedby value provided to the aria-describedby attribute of the input element.
  ariaId: string;
  invalidMessage: string;
};

export const FormControlInvalid: React.FC<FormControlInvalidProps> = ({
  ariaId,
  invalidMessage,
}) => {
  return (
    <FlexBox alignItems={'center'}>
      <Icon
        type={IconType.INVALID}
        height={'1em'}
        color={designToken.error.default}
      />
      &nbsp;
      <Typography
        designToken={'error.default'}
        id={ariaId}
      >
        {invalidMessage}
      </Typography>
    </FlexBox>
  );
};
