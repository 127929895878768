import { useLazyQuery } from '@apollo/client';
import SEARCH_PRODUCTS from '../queries/searchProducts';

const useSearchProduct = () => {
  const [getSearchProducts, { data: searchResponse, error: searchError }] = useLazyQuery(
    SEARCH_PRODUCTS,
    { fetchPolicy: 'no-cache' }
  );
  return { getSearchProducts, searchResponse, searchError };
};

export default useSearchProduct;
