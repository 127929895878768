import { useApolloClient } from '@apollo/client';
import { useDataAccessLayer } from '@lego/b2b-unicorn-data-access-layer/react';
import {
  useSelectedCustomer,
  useUserPreferences,
} from '@lego/b2b-unicorn-shared/components/UserContext';
import { useEffect, useRef } from 'react';

export const useOnPreferredLanguageChange = (queriesToRefetch: string[]) => {
  const { apolloClient } = useDataAccessLayer();
  const legacyApolloClient = useApolloClient();

  const { preferredLanguage } = useUserPreferences();
  const prevPreferredLanguage = useRef(preferredLanguage);

  const selectedCustomer = useSelectedCustomer();
  const prevSelectedCustomer = useRef(selectedCustomer);

  useEffect(() => {
    if (prevSelectedCustomer.current.id !== selectedCustomer.id) {
      prevSelectedCustomer.current = selectedCustomer;
      prevPreferredLanguage.current.id = preferredLanguage.id;
      return;
    }

    if (prevPreferredLanguage.current.id !== preferredLanguage.id) {
      apolloClient.refetchQueries({
        include: queriesToRefetch,
      });
      legacyApolloClient.refetchQueries({
        include: queriesToRefetch,
      });
      prevPreferredLanguage.current = preferredLanguage;
    }
  }, [apolloClient, legacyApolloClient, preferredLanguage, queriesToRefetch, selectedCustomer]);
};
