import { Fragment } from 'react';
import { media } from '@lego/b2b-unicorn-ui-constants';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';
import useSearchProduct from '../../utils/useSearchProducts';
import SearchProducts from '../SearchProducts/SearchProducts';
import { SelectCustomerPopover } from '@lego/b2b-unicorn-shared/components/SelectCustomerPopover';

const TopbarSearchAndUsers = () => {
  const {
    search_showing_results,
    search_loading,
    search_min_chars,
    search_no_results,
    search_header_and_placeholder,
    search_empty_mobile_hint,
    search_error,
  } = useLabels();

  const searchProductsTranslations = {
    search_showing_results,
    search_loading,
    search_min_chars,
    search_no_results,
    search_header_and_placeholder,
    search_empty_mobile_hint,
    search_error,
  };

  const { id: customerId, locale } = useSelectedCustomer();

  return (
    <Fragment>
      <SearchProducts
        searchFunction={useSearchProduct}
        translations={searchProductsTranslations}
        customerId={customerId}
        locale={locale}
        fetchProductImageUrl={(materialId) => {
          return getImageUrl(materialId, ImageType.MAIN_BOX, 40);
        }}
      />
      <div css={{ [media.medium]: { minWidth: 184 } }}>
        <SelectCustomerPopover />
      </div>
    </Fragment>
  );
};

export default TopbarSearchAndUsers;
