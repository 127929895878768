import { css } from '@emotion/react';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { getAssetUrlBlob } from '@lego/b2b-unicorn-ui-utils';
import { usePromise } from '@lego/b2b-unicorn-ui-utils';
import React, { useEffect, useState } from 'react';

import defaultImg from '../../../assets/placeholder-img.png';

const articleStyle = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  padding: baseSpacing * 4,
  backgroundColor: colors.white,
  borderRadius: 8,
  marginBottom: baseSpacing,
  [media.small]: {
    flexDirection: 'row',
  },
});

const imageStyle = css({
  width: '100%',
  height: 153,
  objectFit: 'cover',
  marginBottom: baseSpacing * 2,
  borderRadius: 8,
  [media.small]: {
    width: 272,
    minWidth: 272,
    marginRight: baseSpacing * 2,
    marginBottom: 0,
  },
  [media.medium]: {
    marginRight: baseSpacing * 4,
  },
});

const subHeaderStyle = css({
  lineHeight: 0.9,
  fontSize: font.size.medium,
  fontWeight: font.weight.bold,
  [media.small]: {
    fontSize: 25,
    paddingRight: baseSpacing * 3,
  },
});

const descriptionStyle = css({
  width: '100%',
  fontSize: 16,
  marginBlockStart: baseSpacing,
  marginBlockEnd: baseSpacing,
  [media.small]: {
    paddingRight: baseSpacing * 3,
    fontSize: font.size.small,
  },
});

const filesListStyle = css({
  marginBlockStart: baseSpacing * 3,
  marginBlockEnd: 0,
  paddingInlineStart: 0,
  listStyleType: 'none',
  '> li': {
    width: '100%',
  },
  '> li > button': {
    paddingLeft: 2,
    '> svg': {
      marginRight: 12,
    },
  },
});

type InfoListTileProps = {
  title: string;
  description?: string | null;
  imageUrl?: string | null;
  customerId: number;
  children?: React.ReactNode;
};

const InfoListTile: React.FC<InfoListTileProps> = ({
  title,
  description,
  imageUrl,
  customerId,
  children,
}) => {
  const [imageUrlPromise, setImageUrlPromise] = useState<Promise<string> | undefined>();

  useEffect(() => {
    if (imageUrl) {
      setImageUrlPromise(getAssetUrlBlob(customerId, imageUrl));
    }
  }, [imageUrl, customerId]);

  const url = usePromise(imageUrlPromise);

  return (
    <article css={articleStyle}>
      <section>
        <img
          css={imageStyle}
          src={url || defaultImg}
          alt={url ? 'entryImage' : 'fallbackImage'}
        />
      </section>
      <section>
        <div css={subHeaderStyle}>{title}</div>
        <p css={descriptionStyle}>{description}</p>
        {children && (
          <ul
            css={filesListStyle}
            aria-label={title}
          >
            {children}
          </ul>
        )}
      </section>
    </article>
  );
};

export default InfoListTile;
