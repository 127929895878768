import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Button,
  designToken,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
  Spacer,
} from '@lego/b2b-unicorn-shared/ui';
import {
  IRemovedItem,
  RemovedItemsProductRow,
  RemovedItemsTable,
} from '@lego/b2b-unicorn-ui-components';
import React from 'react';

interface IOrderRemovedItems {
  items: IRemovedItem[];
  closePopup: () => void;
}

const OrderRemovedItems = ({ items, closePopup }: IOrderRemovedItems) => {
  const { popup_invalid_items_header, popup_invalid_items_content, button_ok } = useLabels();

  return (
    <Modal isOpen={true}>
      <ModalHeader subHeader={`${length} ${popup_invalid_items_content}`}>
        <Icon
          type={IconType.ALERT_OUTLINE}
          color={designToken.error.default}
        />
        {popup_invalid_items_header}
      </ModalHeader>
      <Spacer multiplier={4} />
      <RemovedItemsTable>
        {items.map((item) => (
          <RemovedItemsProductRow
            key={item.materialId}
            materialId={item.materialId}
            itemNumber={item.itemNumber}
            reason={item.reason}
            name={item.name}
            quantity={item.quantity}
          />
        ))}
      </RemovedItemsTable>
      <Spacer multiplier={4} />
      <ModalActions>
        <Button
          size="small"
          onClick={closePopup}
        >
          {button_ok}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default OrderRemovedItems;
