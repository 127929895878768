import { css } from '@emotion/react';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { ReactNode } from 'react';

import { SuccessfulBulkCartUpdateSummaryHeader } from './SuccessfulBulkCartUpdateSummaryHeader';

const tableStyle = css({
  width: '100%',
  display: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  borderCollapse: 'collapse',
  fontSize: font.size.small,
  position: 'relative',
  marginTop: 32,
  marginBottom: baseSpacing * 2,
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -2,
    background: `linear-gradient(to top, ${colors.white} 0%, ${colors.white} 5%, ${designToken.transparent.default})`,
    height: 24,
    width: '100%',
    pointerEvents: 'none',
  },
  [media.medium]: {
    display: 'table',
  },
});

const tableBodyStyle = css({
  height: '40vh',
  overflowY: 'auto',
  display: 'block',
  tableLayout: 'auto',
  width: '100%',
});

export const SuccessfulBulkCartUpdateSummaryTable = ({ children }: { children: ReactNode }) => {
  return (
    <table css={tableStyle}>
      <tbody css={tableBodyStyle}>
        <SuccessfulBulkCartUpdateSummaryHeader />
        {children}
      </tbody>
    </table>
  );
};
