import { css } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';

import { FormControlInvalid } from '../../ui/FormControl';
import { baseSpacing, designToken, font } from '../../ui/theme';
import { ToolTip, ToolTipDirection } from '../../ui/ToolTip';
import { CounterMinusButton, CounterPlusButton } from './components';

const counterStyle = css({
  height: baseSpacing * 5,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: designToken.border.default,
  borderRadius: `0.25rem`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: `all 0.2s ease 0s`,

  '&[aria-invalid="true"]': {
    borderColor: designToken.border.error,
    boxShadow: `${designToken.border.error} 0 0 0 1px`,
  },

  '&:has(> input:focus, > button:focus)': {
    borderColor: designToken.border.focus,
    boxShadow: `${designToken.border.focus} 0 0 0 1px`,
  },

  '&:focus': {
    outline: 'none',
  },

  '> input': {
    border: 'none',
    width: '100%',
    // flex will not work with input elements in Firefox unless you add this
    minWidth: 1,
    height: '100%',
    backgroundColor: 'transparent',
    textAlign: 'center',

    '&:focus': {
      outline: 'none',

      '&:placeholder': {
        color: 'transparent',
      },
    },
  },
});

const disabledCounterStyle = css(counterStyle, {
  backgroundColor: designToken.border.subdued,
  color: designToken.text.disabled,
});

const buttonStyle = css({
  WebkitTapHighlightColor: 'transparent',
  border: 'none',
  height: '100%',
  backgroundColor: 'transparent',
  marginTop: 0,
  marginBottom: 0,
  marginRight: baseSpacing,
  marginLeft: baseSpacing,
  padding: 0,
  cursor: 'pointer',

  '&:focus': {
    outline: 'none',
  },

  '> svg': {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    width: 14,
    fill: tokens.color.core.offBlack,
  },
});

const disabledButtonStyle = css(buttonStyle, {
  cursor: 'initial',
  '> svg': {
    fill: tokens.color.core.slate['600'],
    cursor: 'not-allowed',
  },
});

const counterAmountStyle = css({ fontSize: font.size.medium });

const disabledCounterAmountStyle = css(counterAmountStyle, {
  cursor: 'default',
});

type CounterProps = {
  disabled?: boolean;
  value: number | '-';
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  maxValue?: number;
  maxValueHelpText?: string;
  minValue?: number;
  minValueHelpText?: string;
  invalidMessage?: string;
  isInvalid?: boolean;
  customStyle?: ReturnType<typeof css>;
  testDataName?: string;
  name?: string;
};

export const Counter: React.FC<CounterProps> = ({
  disabled = false,
  value = 1,
  onChange,
  onKeyDown,
  customStyle,
  testDataName,
  isInvalid,
  invalidMessage,
  maxValue,
  maxValueHelpText,
  minValue,
  minValueHelpText,
  name,
}) => {
  const [internalValue, setInternalValue] = useState<number | '-' | ''>(value);
  const internalValueRef = useRef(value);
  // Generate a unique id for this counter, prepend the id with 'a' to ensure it's a valid id
  const [counterId] = useState(() => `a${nanoid(10)}`);

  const inputRef = useRef<HTMLInputElement>(null);

  const [showSubtractHelp, setShowSubtractHelp] = useState(false);
  const handleSubtractButtonMouseEnter = () => {
    if (
      typeof internalValue !== 'string' &&
      internalValue <= (minValue ?? Number.NEGATIVE_INFINITY)
    ) {
      setShowSubtractHelp(true);
    }
  };
  const handleSubtractButtonMouseLeave = () => {
    if (showSubtractHelp) {
      setShowSubtractHelp(false);
    }
  };

  const [showAddHelp, setShowAddHelp] = useState(false);
  const handleAddButtonMouseEnter = () => {
    if (
      typeof internalValue !== 'string' &&
      internalValue >= (minValue ?? Number.POSITIVE_INFINITY) &&
      maxValueHelpText
    ) {
      setShowAddHelp(true);
    }
  };
  const handleAddButtonMouseLeave = () => {
    if (showAddHelp) {
      setShowAddHelp(false);
    }
  };

  const handleOnAdd = (e: React.UIEvent) => {
    e.preventDefault();
    let prevValue = internalValue;
    if (typeof prevValue === 'string' && prevValue !== '') {
      prevValue = parseInt(prevValue, 10);
    }
    let nextValue = (prevValue as number) + 1;
    if (nextValue >= (maxValue ?? Number.POSITIVE_INFINITY)) {
      nextValue = maxValue ?? Number.POSITIVE_INFINITY;
      if (maxValueHelpText) {
        setShowAddHelp(true);
      }
    }

    handleOnChange({
      target: { value: nextValue.toString() },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleOnSubtract = (e: React.UIEvent) => {
    e.preventDefault();
    let prevValue = internalValue;
    if (typeof prevValue === 'string' && prevValue !== '') {
      prevValue = parseInt(prevValue, 10);
    }
    let nextValue = (prevValue as number) - 1;
    if (nextValue <= (minValue ?? Number.NEGATIVE_INFINITY)) {
      nextValue = minValue ?? Number.NEGATIVE_INFINITY;
      if (minValueHelpText) {
        setShowSubtractHelp(true);
      }
    }

    handleOnChange({
      target: { value: nextValue.toString() },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextPossibleValue = e.target.value ? parseInt(e.target.value, 10) : 0;
    let nextValue: number | '-' | '' = isNaN(nextPossibleValue) ? 0 : nextPossibleValue;

    if (e.target.value === '-') {
      nextValue = '-';
    }

    setInternalValue(nextValue);
    Object.assign(e, { target: { value: nextValue.toString() } });
    onChange(e);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowUp':
        handleOnAdd(e);
        break;
      case 'ArrowDown':
        handleOnSubtract(e);
        break;
      case 'Enter':
        e.preventDefault();
        handleOnChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        inputRef.current?.blur();
        break;
      default:
        break;
    }

    onKeyDown && onKeyDown(e);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.selectionStart = 0;
    e.target.selectionEnd = e.target.value.length;
  };

  useEffect(() => {
    let nextValue = internalValue;
    if (typeof nextValue === 'string' && nextValue !== '') {
      nextValue = parseInt(nextValue, 10);
    }

    internalValueRef.current = nextValue as number;
  }, [internalValue]);

  useEffect(() => {
    if (value !== internalValueRef.current) {
      setInternalValue(value);
    }
  }, [value]);

  const subtractButtonDisabled =
    disabled || (internalValue as number) <= (minValue ?? Number.NEGATIVE_INFINITY);
  const subtractButtonCss = subtractButtonDisabled ? disabledButtonStyle : buttonStyle;
  const addButtonDisabled =
    disabled || (internalValue as number) >= (maxValue ?? Number.POSITIVE_INFINITY);
  const addButtonCss = addButtonDisabled ? disabledButtonStyle : buttonStyle;

  return (
    <div css={customStyle}>
      <div
        css={[disabled ? disabledCounterStyle : counterStyle]}
        aria-invalid={isInvalid}
      >
        {minValueHelpText && (
          <ToolTip
            message={minValueHelpText}
            open={showSubtractHelp}
            direction={ToolTipDirection.DownRight}
            triggerFullHeight
          >
            <div
              css={{ height: '100%' }}
              onMouseEnter={handleSubtractButtonMouseEnter}
              onMouseLeave={handleSubtractButtonMouseLeave}
            >
              <CounterMinusButton
                onClick={handleOnSubtract}
                disabled={subtractButtonDisabled}
                cssStyles={subtractButtonCss}
              />
            </div>
          </ToolTip>
        )}
        {!minValueHelpText && (
          <CounterMinusButton
            onClick={handleOnSubtract}
            disabled={subtractButtonDisabled}
            cssStyles={subtractButtonCss}
          />
        )}
        <input
          css={disabled ? disabledCounterAmountStyle : counterAmountStyle}
          // type="tel" for numeric keyboard on mobile
          type="tel"
          pattern="[0-9\-]*"
          maxLength={4}
          aria-label="Quantity."
          onFocus={handleOnFocus}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          value={internalValue}
          placeholder={disabled ? '1' : ''}
          disabled={disabled}
          data-e2e={testDataName}
          aria-describedby={isInvalid ? `${counterId}-invalid` : undefined}
          ref={inputRef}
          name={name}
        />
        {maxValueHelpText && (
          <ToolTip
            message={maxValueHelpText}
            open={showAddHelp}
            direction={ToolTipDirection.DownLeft}
            triggerFullHeight
          >
            <div
              onMouseEnter={handleAddButtonMouseEnter}
              onMouseLeave={handleAddButtonMouseLeave}
            >
              <CounterPlusButton
                onClick={handleOnAdd}
                disabled={addButtonDisabled}
                cssStyles={addButtonCss}
              />
            </div>
          </ToolTip>
        )}
        {!maxValueHelpText && (
          <CounterPlusButton
            onClick={handleOnAdd}
            disabled={addButtonDisabled}
            cssStyles={addButtonCss}
          />
        )}
      </div>
      {isInvalid && invalidMessage && (
        <FormControlInvalid
          invalidMessage={invalidMessage}
          ariaId={`${counterId}-invalid`}
        />
      )}
    </div>
  );
};
