import { css, Global } from '@emotion/react';
import { tokens } from '@lego/core-colors';
import React, { useRef } from 'react';

import { Icon, IconType } from '../Icon';
import { Portal } from '../Portal';
import { baseSpacing, colors, designToken, maxDesktopWidth, media } from '../theme';
import { Backdrop } from './Backdrop';

const nonScrollableBodyStyle = css`
  html,
  body {
    height: 100vh;
    overflow: hidden;
  }
`;

const modalContainerStyle = (width: number | string) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    borderTopWidth: 6,
    borderTopStyle: 'solid',
    borderTopColor: tokens.color.core.yellow[200],
    background: colors.white,
    color: designToken.text.default,
    webkitBoxShadow: `1px 1px 8px 0px ${tokens.color.core.gray[1300]}` + 50,
    mozBoxShadow: `1px 1px 8px 0px ${tokens.color.core.gray[1300]}` + 50,
    boxShadow: `1px 1px 8px 0px ${tokens.color.core.gray[1300]}` + 50,
    paddingRight: baseSpacing * 3,
    paddingLeft: baseSpacing * 3,
    paddingBottom: baseSpacing * 3,
    paddingTop: baseSpacing * 2,
    position: 'relative',
    marginLeft: baseSpacing,
    marginRight: baseSpacing,
    width: `calc(100% - ${baseSpacing * 6}px)`,
    maxWidth: width,

    [media.medium]: {
      width: width,
      borderRadius: 4,
      maxWidth: maxDesktopWidth,
    },
  });

const closeButtonStyle = css({
  backgroundColor: 'transparent',
  color: designToken.text.default,
  padding: 0,
  margin: 0,
  border: 'none',
  position: 'absolute',
  top: baseSpacing * 2,
  right: baseSpacing * 2,
  cursor: 'pointer',
  zIndex: 1000,
  height: 32,
  width: 32,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transitionProperty: 'background-color',
  transitionDuration: '100ms',
  '> svg': {
    height: 18,
    width: 18,
  },
  '&: hover': {
    backgroundColor: tokens.color.core.gray[50],
  },
});

type ModalProps = {
  onClose?: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  width?: number | string;
};

export const Modal: React.FC<ModalProps> = ({ children, width = 620, onClose, isOpen }) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  const handleOnBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!onClose) {
      return;
    }

    if (backdropRef.current === e.target) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal portalId={'modal'}>
      <Global styles={nonScrollableBodyStyle} />
      <Backdrop
        onClick={handleOnBackdropClick}
        ref={backdropRef}
      >
        <div css={modalContainerStyle(width)}>
          {onClose && (
            <button
              css={closeButtonStyle}
              onClick={onClose}
            >
              <Icon type={IconType.CLOSE} />
            </button>
          )}
          {children}
        </div>
      </Backdrop>
    </Portal>
  );
};
