import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { tableHeaderRightStyle, tableHeaderStyle } from '@lego/b2b-unicorn-ui-checkout-flow';
import { baseSpacing, colors, media } from '@lego/b2b-unicorn-ui-constants';
import React from 'react';

const quantityWidthStyle = css({ width: 120, paddingRight: baseSpacing * 3 });
const priceWidthStyle = css({ width: 160 });

const localTableHeadStyle = css({
  display: 'none',
  position: 'sticky',
  width: '100%',
  top: 0,
  [media.small]: {
    display: 'table-row-group',
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 24,
      width: '100%',
      bottom: -23,
      left: 0,
      background: `linear-gradient(to bottom, ${colors.white} 0%, ${colors.white} 5%, ${designToken.transparent.default})`,
      pointerEvents: 'none',
    },
  },
});

type ColumnNames = 'item_id' | 'name' | 'quantity' | 'price';

type RemovedItemsTableHeaderProps = {
  children?: never;
  columns?: ColumnNames[];
};

export const RemovedItemsTableHeader: React.FC<RemovedItemsTableHeaderProps> = ({
  columns = ['item_id', 'name', 'quantity', 'price'],
}) => {
  const { item_id, name, quantity } = useLabels();
  // it's prettending to be a header, but needs to be a sticky row, so rest of table is scrollable
  return (
    <tr css={localTableHeadStyle}>
      {columns.find((column) => column === 'item_id') && (
        <th css={[tableHeaderStyle]}>{item_id}</th>
      )}
      {columns.find((column) => column === 'name') && <th css={[tableHeaderStyle]}>{name}</th>}
      {columns.find((column) => column === 'quantity') && (
        <th css={[tableHeaderRightStyle, quantityWidthStyle]}>{quantity}</th>
      )}
      {columns.find((column) => column === 'price') && (
        <th css={[tableHeaderRightStyle, priceWidthStyle]} />
      )}
    </tr>
  );
};
