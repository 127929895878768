import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { TopbarDataContext } from '../../context/Topbar/TopbarDataContext';
import { useDataAccessLayer } from '..';

type TopbarParameters = Parameters<typeof TopbarDataContext.prototype.getTopbar>;

export const useTopbar = (...parameters: TopbarParameters) => {
  const dataAccessLayer = useDataAccessLayer();
  const queryResult = useMemo(() => {
    return dataAccessLayer.topbar.getTopbar(parameters[0], {
      ...parameters[1],
    });
  }, [dataAccessLayer.topbar, parameters]);

  return useQuery(queryResult.query, {
    variables: queryResult.variables,
    notifyOnNetworkStatusChange: true,
    client: dataAccessLayer.apolloClient,
    ...parameters[1],
  });
};
