import React from 'react';

import { Button, ButtonType } from '../../ui/Button';
import { Modal, ModalActions, ModalHeader } from '../../ui/Modal';
import { Spacer } from '../../ui/Spacer';
import { useClaimItemFormContext } from './ClaimItemFormContext';

type ClaimItemFormProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};
export const ClaimItemForm: React.FC<ClaimItemFormProps> = ({ open, onClose, children }) => {
  const {
    translations: { header, buttons },
    invoiceItem: { itemNumber, name },
    formik,
    resetForm,
    claimItem,
  } = useClaimItemFormContext();

  const handleCloseClick = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={open}>
      <ModalHeader subHeader={`${itemNumber} - ${name}`}>{header}</ModalHeader>
      <Spacer />
      <div>
        {children}
        <ModalActions>
          <Button
            onClick={handleCloseClick}
            type={ButtonType.SECONDARY}
            size={'small'}
          >
            {buttons.close}
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            size={'small'}
            disabled={!formik.values.claimType || !formik.isValid || formik.isSubmitting}
          >
            {claimItem ? buttons.updateClaim : buttons.addClaim}
          </Button>
        </ModalActions>
      </div>
    </Modal>
  );
};
