import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApmService } from '@lego/b2b-unicorn-apm';
import { normalizeGraphQLError } from '@lego/b2b-unicorn-shared/helpers';
import { logger } from '@lego/b2b-unicorn-shared/logger';
import { ICartItem } from '@lego/b2b-unicorn-ui-checkout-flow';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { cloneDeep } from 'lodash';

interface ICart {
  items: ICartItem[];
}

interface IGetLaunchCartPreview {
  getCart: ICart;
}

export interface IAppSyncLinkConfig {
  url: string;
  region: string;
  auth: AuthOptions;
}

const apolloLogger = logger.createLogger('ApolloClient');
const loggerLink = new ApolloLink((operation, forward) => {
  const currentTransaction = ApmService.instance?.getCurrentTransaction();
  const span = currentTransaction?.startSpan(
    `Operation: ${operation.operationName}, Called with: ${JSON.stringify(operation.variables)}`,
    'graphql',
    {
      blocking: true,
    }
  );

  return forward(operation).map((data) => {
    span?.end();

    return data;
  });
});
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      normalizeGraphQLError(graphQLError);
      apolloLogger.error(graphQLError, {
        stackTrace: graphQLError,
      });
    }
  }
});

// from https://github.com/awslabs/aws-mobile-appsync-sdk-js#using-authorization-and-subscription-links-with-apollo-client-no-offline-support
const apolloClient = (appSyncLinkConfig: IAppSyncLinkConfig) =>
  new ApolloClient({
    // connectToDevTools: true,
    link: ApolloLink.from([
      loggerLink,
      errorLink,
      createAuthLink(appSyncLinkConfig) as unknown as ApolloLink, // Hacking some weird type behavior between packages
      createSubscriptionHandshakeLink(appSyncLinkConfig) as unknown as ApolloLink, // Here too
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Cart: {
          fields: {
            items: {
              merge: (
                existingCartItems: IGetLaunchCartPreview['getCart']['items'] = [],
                incomingCartItems: IGetLaunchCartPreview['getCart']['items'] = []
              ) => {
                const nextCartItems = cloneDeep(existingCartItems);
                incomingCartItems.forEach((cartItem) => {
                  const inExistingCart = nextCartItems.findIndex(
                    (c) => c.product.materialId === cartItem.product.materialId
                  );
                  if (inExistingCart > -1) {
                    nextCartItems[inExistingCart] = {
                      ...nextCartItems[inExistingCart],
                      ...cartItem,
                      product: {
                        ...nextCartItems[inExistingCart].product,
                        ...cartItem.product,
                      },
                    };
                  } else {
                    nextCartItems.push(cartItem);
                  }
                });

                return nextCartItems;
              },
            },
          },
        },
      },
    }),
    connectToDevTools: false,
  });

export default apolloClient;
