import { gql } from '@apollo/client';

export const ORDERS = gql`
  query orders($customerId: Int!, $filter: OrderSearchFilter) {
    orders(customerId: $customerId, filter: $filter) {
      totalCount
      orders {
        orderNumber
        orderName
        orderDate
        shipTo {
          id
          name
          city
          leadtimeInDays
        }
        requestedDeliveryDate
        orderTotal {
          estimatedNetInvoicedPrice
          currency
        }
        orderStatus
        fulfillmentStatus
        hasPdfOutput
        items {
          materialId
          orderedPieces
          rejectedPieces
          name
        }
        type
      }
    }
  }
`;
