import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  FinalRowItem,
  RowStatusReason,
} from '@lego/b2b-unicorn-shared/components/BulkQuickAdd/types';
import {
  Button,
  ButtonType,
  designToken,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
} from '@lego/b2b-unicorn-shared/ui';
import { RemovedItemsProductRow, RemovedItemsTable } from '@lego/b2b-unicorn-ui-components';
import React from 'react';

type ReplenishBulkQuickAddRemovedItemsProps = {
  confirmModalOpen: boolean;
  setConfirmModalOpen: (value: boolean) => void;
  items: FinalRowItem[];
  handleRemoveInvalidItems: () => void;
};

export const BulkQuickAddRemovedItems: React.FC<ReplenishBulkQuickAddRemovedItemsProps> = ({
  confirmModalOpen,
  setConfirmModalOpen,
  items,
  handleRemoveInvalidItems,
}) => {
  const { popup_remove_items_confirmation_header, button_cancel, button_remove } = useLabels();

  return (
    <Modal isOpen={confirmModalOpen}>
      <ModalHeader>
        <Icon
          type={IconType.ALERT_OUTLINE}
          color={designToken.error.default}
        />
        {popup_remove_items_confirmation_header}
      </ModalHeader>
      <RemovedItemsTable headers={['item_id', 'name', 'price']}>
        {items
          .filter((item) => item.rowStatusReason !== RowStatusReason.Valid)
          .map((item, index) => (
            <RemovedItemsProductRow
              key={`${item.productLookupValue}-${index}`}
              materialId={item.productLookupValue}
              itemNumber={item.productLookupValue}
              reason={item.rowStatusReason ?? ''}
              name={item.product?.name ?? ''}
            />
          ))}
      </RemovedItemsTable>
      <ModalActions>
        <Button
          type={ButtonType.SECONDARY}
          onClick={() => setConfirmModalOpen(false)}
        >
          {button_cancel}
        </Button>
        <Button onClick={handleRemoveInvalidItems}>{button_remove}</Button>
      </ModalActions>
    </Modal>
  );
};
