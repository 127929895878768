import { css } from '@emotion/react';
import { ContentstackLink } from '@lego/b2b-unicorn-shared/components/ContentstackLink';
import { KEYCODE_STRING } from '@lego/b2b-unicorn-shared/constants';
import { designToken, Icon, IconType } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, font, maxDesktopWidth, media } from '@lego/b2b-unicorn-ui-constants';
import { KeyboardEvent } from 'react';

import { ILink, NotificationType } from '../NotificationType';
import NotificationIcon from './NotificationIcon';

const bannerStyle = (
  type: NotificationType.DEFAULT | NotificationType.WARNING | NotificationType.ERROR
) =>
  css({
    backgroundColor: {
      [NotificationType.DEFAULT]: designToken.information.muted,
      [NotificationType.WARNING]: designToken.warning.muted,
      [NotificationType.ERROR]: designToken.error.muted,
    }[type],
    zIndex: 1,
    width: '100%',
    minHeight: 64,
    padding: 0,
    position: 'relative',
    top: 0,
    left: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: {
      [NotificationType.DEFAULT]: designToken.information.default,
      [NotificationType.WARNING]: designToken.warning.default,
      [NotificationType.ERROR]: designToken.error.default,
    }[type],
  });

const contentStyle = css({
  width: '100%',
  maxWidth: maxDesktopWidth,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  color: designToken.text.default,
  fontSize: font.size.small,
  padding: 0,
  paddingRight: baseSpacing * 6,
  '> p': {
    alignSelf: 'center',
    '> *': {
      color: designToken.interactive.default,
      paddingRight: baseSpacing / 2,
      paddingLeft: baseSpacing / 2,
    },
  },
  [media.small]: {
    alignItems: 'center',
    paddingRight: baseSpacing * 8,
    paddingLeft: baseSpacing * 3,
  },
  marginLeft: 'auto',
  marginRight: 'auto',
});

const closeStyle = css({
  backgroundColor: 'rgba(0, 100, 100, 0)',
  border: 'none',
  height: 30,
  width: 30,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 16,
  right: baseSpacing * 2,
  transitionProperty: 'background-color',
  transitionDuration: '150ms',
  outline: 'none',
  cursor: 'pointer',
  [media.small]: {
    right: baseSpacing * 3,
  },
  '> svg': {
    height: baseSpacing * 2,
    width: baseSpacing * 2,
    fill: designToken.text.default,
    transitionProperty: 'fill',
    transitionDuration: '150ms',
  },
  '&:hover, :focus, :active': {
    backgroundColor: 'rgba(0, 100, 100, 0.1)',
  },
});

const iconContainerStyle = css({
  height: 64,
  display: 'flex',
  alignItems: 'center',
});

interface INotificationBanner {
  message: string;
  link?: ILink;
  type?: NotificationType.DEFAULT | NotificationType.WARNING | NotificationType.ERROR;
  removeBannerHandler?: () => void;
}

const NotificationBanner = ({
  message,
  link,
  type = NotificationType.DEFAULT,
  removeBannerHandler,
}: INotificationBanner) => {
  const removeBannerKeyHandler = (e: KeyboardEvent) => {
    if (removeBannerHandler && e.code === KEYCODE_STRING.ENTER) {
      removeBannerHandler();
    }
  };

  return message && type ? (
    <div css={bannerStyle(type)}>
      <div css={contentStyle}>
        <div css={iconContainerStyle}>
          <NotificationIcon type={type} />
        </div>
        <p>
          {message}{' '}
          {link && link.title && (
            <ContentstackLink
              url={link.url}
              title={link.title}
              assetUid={link.assetUid}
            />
          )}
        </p>
      </div>
      {/* Button is a div because iOS Safari apparently has an issue when rendering SVGs inside <button> elements. */}
      {removeBannerHandler && (
        <div
          role="button"
          tabIndex={0}
          css={closeStyle}
          onClick={removeBannerHandler}
          onKeyUp={removeBannerKeyHandler}
        >
          <Icon type={IconType.CLOSE} />
        </div>
      )}
    </div>
  ) : null;
};

export default NotificationBanner;
