import { Authentication } from '@lego/b2b-unicorn-authentication';

import handleAuthorisedFetch from './handleAuthorisedFetch';

const getContent = async (customerId: number, endpoint: string) => {
  try {
    const token = await Authentication.instance.getIdToken();
    if (!token) {
      return null;
    }
    const response = await handleAuthorisedFetch(endpoint, token, customerId.toString());
    return response?.json();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading data: ', e);
    return null;
  }
};

export default getContent;
