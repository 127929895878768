import { deepEqual } from 'fast-equals';
import React, { useMemo } from 'react';

import { formatCurrency } from '../../helpers/formatCurrency';
type Maybe<T> = T | null;
type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: never; output: never };
};
type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};
type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  estimatedNetInvoicedPrice: Scalars['Float']['output'];
  grossPrice?: Maybe<Scalars['Float']['output']>;
  listPrice?: Maybe<Scalars['Float']['output']>;
  netInvoicedPrice?: Maybe<Scalars['Float']['output']>;
};
type PriceType = 'nipPrice' | 'grossPrice' | 'listPrice';
type PriceWrappers = {
  nipPrice?: React.FC<{ children: React.ReactNode }>;
  grossPrice?: React.FC<{ children: React.ReactNode }>;
  listPrice?: React.FC<{ children: React.ReactNode }>;
};
interface Prices {
  nipPrice?: string;
  grossPrice?: string;
  listPrice?: string;
}

interface PriceProps {
  children: Price | Money | null | undefined;
  locale: string;
  multiplier?: number;
  fallbackValue?: string;
  wrappers?: PriceWrappers;
  priceTypes?: PriceType[] | 'auto';
}

const defaultWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span>{children}</span>
);

const defaultWrappers = {
  nipPrice: defaultWrapper,
  grossPrice: defaultWrapper,
  listPrice: defaultWrapper,
};

export const Price: React.FC<PriceProps> = React.memo(
  ({
    children,
    locale,
    fallbackValue = '-',
    multiplier = 1,
    wrappers = defaultWrappers,
    priceTypes = 'auto',
  }) => {
    const Wrappers = useMemo(() => {
      return {
        nipPrice: wrappers.nipPrice || defaultWrappers.nipPrice,
        grossPrice: wrappers.grossPrice || defaultWrappers.grossPrice,
        listPrice: wrappers.listPrice || defaultWrappers.listPrice,
      };
    }, [wrappers.grossPrice, wrappers.listPrice, wrappers.nipPrice]);

    const prices = useMemo(() => {
      const prices: Prices = {};

      if (children === null || children === undefined) {
        return prices;
      }

      if (children.__typename === 'Money') {
        Object.assign(prices, {
          grossPrice: formatCurrency(locale, children.amount * multiplier, children.currency),
        });

        return prices;
      }

      if (children.__typename === 'Price') {
        Object.assign(prices, {
          nipPrice:
            children.netInvoicedPrice || children.estimatedNetInvoicedPrice
              ? formatCurrency(
                  locale,
                  (children.netInvoicedPrice! || children.estimatedNetInvoicedPrice!) * multiplier,
                  children.currency
                )
              : fallbackValue,
          grossPrice:
            children.grossPrice &&
            children.grossPrice !==
              (children.netInvoicedPrice || children.estimatedNetInvoicedPrice)
              ? formatCurrency(locale, children.grossPrice * multiplier, children.currency)
              : undefined,
          listPrice: children.listPrice
            ? formatCurrency(locale, children.listPrice * multiplier, children.currency)
            : undefined,
        });
      }

      return prices;
    }, [children, locale, multiplier]);

    if (children === null || children === undefined) {
      return <>{fallbackValue}</>;
    }

    return (
      <>
        {priceTypes === 'auto' && (
          <>
            {prices.nipPrice && <Wrappers.nipPrice>{prices.nipPrice}</Wrappers.nipPrice>}
            {prices.grossPrice && <Wrappers.grossPrice>{prices.grossPrice}</Wrappers.grossPrice>}
            {!prices.grossPrice && prices.listPrice && (
              <Wrappers.listPrice>{prices.listPrice}</Wrappers.listPrice>
            )}
          </>
        )}
        {priceTypes !== 'auto' &&
          priceTypes.map((priceType) => {
            if (prices[priceType]) {
              const Wrapper = Wrappers[priceType];
              return <Wrapper key={priceType}>{prices[priceType]}</Wrapper>;
            }

            return null;
          })}
      </>
    );
  },
  (prevProps, nextProps) => deepEqual(prevProps, nextProps)
);

Price.displayName = 'Price';
