import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  Button,
  designToken,
  Icon,
  IconType,
  Modal,
  ModalActions,
  ModalHeader,
} from '@lego/b2b-unicorn-shared/ui';
import React, { ReactNode } from 'react';

import { SuccessfulBulkCartUpdateSummaryTable } from './';

type SuccessfulBulkCartUpdateSummaryPopupProps = {
  children: ReactNode;
  onClose: () => void;
};

export const SuccessfulBulkCartUpdateSummary: React.FC<
  SuccessfulBulkCartUpdateSummaryPopupProps
> = ({ children, onClose }) => {
  const { excel_upload_summary, button_ok } = useLabels();

  return (
    <Modal isOpen>
      <ModalHeader>
        <Icon
          type={IconType.CHECK_OUTLINE}
          color={designToken.success.default}
        />
        {excel_upload_summary}
      </ModalHeader>
      <SuccessfulBulkCartUpdateSummaryTable>{children}</SuccessfulBulkCartUpdateSummaryTable>
      <ModalActions>
        <Button
          size="small"
          onClick={() => onClose()}
        >
          {button_ok}
        </Button>
      </ModalActions>
    </Modal>
  );
};
