import { gql } from '@apollo/client';

export const UPCOMING_DELIVERIES = gql`
  query UpcomingDeliveries($customerId: Int!, $filter: UpcomingDeliverySearchFilter) {
    upcomingDeliveries(customerId: $customerId, filter: $filter) {
      totalCount
      upcomingDeliveries {
        itemNumber
        materialId
        itemName
        orderNumber
        orderName
        shipTo {
          id
          name
          city
        }
        orderedPieces
        expectedDeliveryDate {
          pieces
          date
        }
      }
    }
    products(customerId: $customerId) {
      products {
        materialId
      }
    }
  }
`;
