import { Authentication } from '@lego/b2b-unicorn-authentication';

import handleAuthorisedFetch from './handleAuthorisedFetch';

const getAssetResponse = async (customerId: number, assetUrl: string) => {
  try {
    const token = await Authentication.instance.getIdToken();

    if (token) {
      const response = await handleAuthorisedFetch(assetUrl, token, customerId.toString());
      return response;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading asset: ', e);
    return null;
  }
};

const getAssetUrlBlob = async (customerId: number, assetUrl: string) => {
  const assetResponse = await getAssetResponse(customerId, assetUrl);

  if (assetResponse) {
    const blob = await assetResponse?.blob();
    const urlBlob = URL.createObjectURL(blob);
    return urlBlob;
  } else {
    // eslint-disable-next-line no-console
    console.warn('Unexpected result when creating urlBlob from asset service response.');
    return '';
  }
};

export default getAssetUrlBlob;
