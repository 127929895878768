import { css } from '@emotion/react';
import React from 'react';

import { baseSpacing, media } from '../theme';

const actionsContainerStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: baseSpacing * 2,
  flexDirection: 'column',
  gap: baseSpacing * 2,
  '> button': {
    width: '100%',
  },
  [media.small]: {
    flexDirection: 'row',
  },
  [media.medium]: {
    '> button': { width: 'auto', minWidth: 124 },
  },
});

type ModalActionsProps = {
  children: React.ReactNode;
};

export const ModalActions: React.FC<ModalActionsProps> = ({ children }) => {
  return <div css={actionsContainerStyle}>{children}</div>;
};
