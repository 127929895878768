import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import { designToken } from '@lego/b2b-unicorn-shared/ui';
import { baseSpacing, colors, font, media } from '@lego/b2b-unicorn-ui-constants';
import { getImageUrl, ImageType } from '@lego/b2b-unicorn-ui-utils';

import { ProductImage } from '../';

const tableRowStyle = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: baseSpacing * 3,
  [media.small]: {
    display: 'table-row',
    position: 'initial',
  },
});

const itemIdCellStyle = css({
  position: 'absolute',
  left: baseSpacing,
  top: 13,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  [media.small]: {
    position: 'initial',
    width: 90,
  },
});

const imageWrapperStyle = css({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  '> img, svg': {
    height: 30,
    width: 'auto',
  },
});

const itemIDFontStyle = css({
  fontWeight: font.weight.bold,
  marginLeft: baseSpacing / 2,
});

const tableDataStyle = css({
  display: 'block',
  background: colors.white,

  [media.small]: {
    display: 'table-cell',
    verticalAlign: 'center',
    padding: baseSpacing,
    fontSize: font.size.small,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.klik.slate100,
  },
});

const nameCellStyle = css(tableDataStyle, {
  borderTopColor: colors.klik.lightBlue400,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderBottomColor: colors.klik.slate100,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  width: '100%',
  paddingTop: baseSpacing * 2,
  paddingBottom: baseSpacing * 2,
  paddingLeft: 120,
  marginBottom: baseSpacing / 2,
  [media.small]: {
    padding: baseSpacing,
    borderTop: 'none',
    width: 'auto',
  },
  [media.medium]: { width: 300 },
});

const quantityStyle = css(tableDataStyle, {
  paddingLeft: 120,
  [media.small]: {
    textAlign: 'right',
    paddingRight: baseSpacing * 3,
    paddingLeft: 0,
  },
});

const errorCellStyle = css(tableDataStyle, {
  color: designToken.error.default,
  paddingLeft: 120,
  [media.small]: {
    paddingLeft: 0,
    paddingTop: baseSpacing * 2,
    paddingBottom: baseSpacing * 2,
  },
});
export interface IRemovedItem {
  materialId?: number | null;
  itemNumber?: number | null;
  name?: string | null;
  reason: string;
  quantity?: number;
  orderBlockedInfo?: string;
}

export const RemovedItemsProductRow = ({
  materialId,
  itemNumber,
  reason,
  name,
  quantity,
  orderBlockedInfo,
}: IRemovedItem) => {
  const {
    not_in_catalog,
    out_of_stock,
    not_available,
    retired,
    max_quantity_exceeded,
    not_in_window,
    order_blocked,
    deadline_overdue,
    replenish_item,
    quantity_invalid,
  } = useLabels();

  const reasonMessage = (messageKey: string) => {
    return {
      NOT_IN_CATALOG: not_in_catalog,
      NOT_AVAILABLE: not_available,
      MARKED_NOT_AVAILABLE: not_available,
      OUT_OF_STOCK: out_of_stock,
      REMOVED_BY_PRICING_SERVICE: '',
      RETIRED: retired,
      MAX_QUANTITY_EXCEEDED: max_quantity_exceeded,
      NOT_IN_WINDOW: not_in_window,
      ORDER_BLOCKED: `${order_blocked} ${orderBlockedInfo}`,
      DEADLINE_OVERDUE: deadline_overdue,
      REPLENISH_ITEM: replenish_item,
      QUANTITY_INVALID: quantity_invalid,
    }[messageKey];
  };

  const imageUrl = materialId && getImageUrl(materialId, ImageType.MAIN_BOX, 120);

  return (
    <tr css={tableRowStyle}>
      <td css={tableDataStyle}>
        <div css={itemIdCellStyle}>
          <div css={imageWrapperStyle}>
            {imageUrl && (
              <ProductImage
                src={imageUrl}
                alt="box image"
              />
            )}
          </div>
          <div css={itemIDFontStyle}>{itemNumber}</div>
        </div>
      </td>
      <td css={nameCellStyle}>{name}</td>
      {quantity !== undefined && <td css={quantityStyle}>{quantity}</td>}
      <td css={errorCellStyle}>{reasonMessage(reason)}</td>
    </tr>
  );
};
