import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePromise = <T>(promise?: Promise<T>) => {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    let mounted = true;
    const waitForPromise = async () => {
      if (mounted) {
        setValue(await promise);
      }
    };
    waitForPromise();
    return () => {
      mounted = false;
    };
  }, [promise]);

  return value;
};
